import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { isMobileDevice, hexToRGBA } from '../../../../utils/generalUtils';
import { getPersonalizationItems } from '../../../../api/RoomsController';
import { setColor, setFont } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import PreviewSection from './components/PreviewSection';
import Banner from '../../../../components/layout/ui/Banner';
import BackgroundContainer from '../../../../components/common/Backgrounds/BackgroundContainer';
import MobileSimpleNav from '../../components/MobileSimpleNav';

const CustomizationPage = ({ token }) => {
    const [searchParams] = useSearchParams();
    const roomId = searchParams.get('roomId');
    const userId = searchParams.get('userId');

    const isMobile = isMobileDevice();
    const [frames, setFrames] = useState([]);
    const [showMobileImagePreview, setShowMobileImagePreview] = useState(false);
    const [selectedFrame, setSelectedFrame] = useState(null);

    const [openBanner, setOpenBanner] = useState('');
    const [successBannerMessage, setSuccessBannerMessage] = useState('');
    const [bannerType, setBannerType] = useState('');

    const updateShowMobileImage = () => {
        setShowMobileImagePreview((prev) => !prev);
    };

    const updateSuccessBanner = (message) => {
        if (message !== '') {
            setBannerType('SUCCESS');
            setSuccessBannerMessage(message);
            setOpenBanner(true);

            fetchRoomPersonalizationItem();
        }
    };

    useEffect(() => {
        fetchRoomPersonalizationItem();
    }, [roomId]);

    const fetchRoomPersonalizationItem = async () => {
        const response = await getPersonalizationItems(roomId, token);

        if (response.status === 200) {
            const frames = response.data.room_personalization_items;
            const newFrames = await frames
                .map((frame) => {
                    frame.isMain = false;
                    return frame;
                })
                .sort((a, b) => {
                    if (a.displayName < b.displayName) {
                        return -1;
                    }
                    if (a.displayName > b.displayName) {
                        return 1;
                    }
                    return 0;
                });

            newFrames.unshift({
                id: 'main-intraverse-portal-id',
                displayName: 'Intraport Preview',
                roomId,
                frameType: 'thumbnail',
                isMain: true,
                s3Url: response.data.roomMainImageFileUrl,
                fileUrl: null,
                fileName: null,
                externalUrl: response.data.roomMainImageExternalUrl,
            });

            setFrames(newFrames);

            if (!selectedFrame) {
                setSelectedFrame(newFrames[0]);
            } else {
                const updatedSelectedFrame = newFrames.find((frame) => frame.id === selectedFrame?.id);
                setSelectedFrame(updatedSelectedFrame);
            }
        }
    };

    const handleClick = (frame) => {
        if (isMobile) {
            setShowMobileImagePreview((prev) => !prev);
        }
        setSelectedFrame(frame);
    };

    const OverviewComponent = () => (
        <OverViewSection>
            <Title title={'Overview'} description={'Customize your space by selecting an option below.'} />
            {frames.map((frame, i) => {
                let isActive = false;
                if (frame.id && selectedFrame) {
                    isActive = selectedFrame.id === frame.id;
                }

                if (i === 0) {
                    return (
                        <>
                            <div key={uniqueId()}>
                                <ClickableDiv
                                    style={{ margin: '20px 0' }}
                                    key={uniqueId()}
                                    isActive={isActive}
                                    onClick={() => handleClick(frame)}
                                >
                                    {frame.displayName}
                                </ClickableDiv>
                            </div>
                            <div style={{ borderBottom: '4px dashed #2B2A39' }} />
                            <MediaFramesTitle>MEDIA FRAMES</MediaFramesTitle>
                        </>
                    );
                }

                return (
                    <ClickableDiv
                        style={{ margin: '10px 0' }}
                        key={uniqueId()}
                        isActive={isActive}
                        onClick={() => handleClick(frame)}
                    >
                        {frame.displayName}
                    </ClickableDiv>
                );
            })}
        </OverViewSection>
    );

    const MobileViewManager = () => {
        if (showMobileImagePreview) {
            return (
                <PreviewSection
                    token={token}
                    userId={userId}
                    key={selectedFrame?.id}
                    setSuccessBannerMessage={updateSuccessBanner}
                    selectedFrame={selectedFrame}
                />
            );
        }

        return (
            <div>
                <OverviewComponent />
            </div>
        );
    };

    return (
        <>
            <Banner
                message={successBannerMessage}
                type={bannerType}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                showCloseButton={false}
                timer={8000}
            />
            {isMobile && (
                <MobileSimpleNav title={'Overview'} func={updateShowMobileImage} showTitle={showMobileImagePreview} />
            )}

            <DesktopLayout>
                <BackgroundContainer
                    height={'100vh'}
                    width={'100%'}
                    backgroundColor={`${setColor.innerProfileContainer}`}
                    variant="DiamondBackground"
                    style={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        maxWidth: '1280px',
                        padding: '0',
                        borderRight: `3px solid ${hexToRGBA(setColor.accent4, 0.1)}`,
                        borderLeft: `3px solid ${hexToRGBA(setColor.accent4, 0.1)}`,
                    }}
                >
                    {!isMobile ? (
                        <>
                            <OverviewComponent />
                            <PreviewSection
                                token={token}
                                userId={userId}
                                key={selectedFrame?.id}
                                setSuccessBannerMessage={updateSuccessBanner}
                                selectedFrame={selectedFrame}
                            />
                        </>
                    ) : (
                        <MobileViewManager />
                    )}
                </BackgroundContainer>
            </DesktopLayout>
        </>
    );
};

const MobileContainer = styled.div``;

// Desktop
const DesktopLayout = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #1b1b23;
`;

const MediaFramesTitle = styled.h1`
    font-family: Barlow;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: left;
    padding-top: 25px;
    color: ${setColor.loadingButtonBg};
`;

const ClickableDiv = styled.div`
    cursor: pointer;
    background-color: ${(props) => (props.isActive ? setColor.purpleAccentBackground : '')};
    color: ${(props) => (props.isActive ? setColor.iconColor3 : setColor.white)};
    height: 64px;
    font-size: 20px;
    font-family: ${setFont.barlow};
    font-weight: ${(props) => (props.isActive ? '600' : '400')};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    border: ${(props) => (props.isActive ? '' : '2px solid #504C5C')};
    border-radius: 5px;
    &:hover {
        background-color: ${setColor.lightPurpleInput};
        color: ${setColor.iconColor3};
        font-weight: 600;
    }
`;

const OverViewSection = styled.div`
    height: fit-content;
    width: 40%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 960px) {
        width: 100%;
        padding: 0 15px 15px 15px;
    }
`;

export default CustomizationPage;
