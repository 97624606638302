import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import purpleX from '../../assets/images/buttonIcons/purpleX.png';
import { sendVagonMessage } from '../vagon/helpers/utils';
import * as constants from '../../constants';
import Modal from '../../pages/console/components/Modal';
import Typography from '../../components/common/text/Typography';
import Button from '../../components/common/button/Button';
import { setColor } from '../../assets/styles/styles';
import IntraverseLogoFull from '../../pages/landingPages/intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';

const ArcadeGame = ({ onClose }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const modalMessage = useRef();
    const [selectedGameUrl, setSelectedGameUrl] = useState(null);

    const games = [
        { id: 1, name: 'Webrcade', url: 'https://play.webrcade.com/' },
        { id: 2, name: 'Skribbl.io!', url: 'https://skribbl.io/' },
        { id: 3, name: 'Roulette', url: 'https://www.247roulette.org' },
        { id: 4, name: 'Blackjack', url: 'https://freegames.org/blackjack' },
        { id: 5, name: 'FreePoker', url: 'https://www.247freepoker.com' },
    ];

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.messageType === 'closeGame') {
                modalMessage.current = event.data?.messageData?.message || 'An error occurred. Please try again later.';
                updateModal();
            }
        }

        window.addEventListener('message', handleMessage);

        // Cleanup function
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={'30px 0'}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={'15px 0px 0px 0px'}
                            label="Okay"
                            height={'30px'}
                            variant="OutlinedButton"
                            onClick={() => {
                                // sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {selectedGameUrl ? (
                <>
                    <TZiframeBanner>
                        You are playing an external game - Click the 'X' icon to return to{' '}
                        <img
                            style={{ height: '20px', width: 'auto', marginLeft: '10px' }}
                            alt="Intraverse Logo Full"
                            src={IntraverseLogoFull}
                        />
                    </TZiframeBanner>
                    <iframe
                        title="Lion Gaming Iframe Content"
                        id="splash"
                        // scrolling="no"
                        allow="autoplay"
                        style={{
                            position: 'absolute',
                            overflow: 'auto',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            padding: 0,
                            border: 0,
                            zIndex: 9998,
                            top: 0,
                            left: 0,
                        }}
                        src={selectedGameUrl}
                    />
                    <CloseButton
                        type="button"
                        onClick={() => {
                            // sendCloseEventLog();
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            setSelectedGameUrl(null);
                        }}
                    >
                        <StyledImage src={purpleX} alt="Close" />
                    </CloseButton>
                </>
            ) : (
                <GameMenuModal>
                    <ModalContent>
                        <Typography variant={'h2PurpleHeading'}> Arcade Game Menu </Typography>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '24px', marginTop: '1rem' }}
                            variant={'LandingPageTSubtitle'}
                        >
                            Select a game library containing thousands of games
                        </Typography>
                        <Grid>
                            {games.map((game, index) => (
                                <GameIcon key={index} onClick={() => setSelectedGameUrl(game.url)}>
                                    🎮 {game.name} {game.name === 'Arkadium' ? '✦' : ''}
                                </GameIcon>
                            ))}
                        </Grid>
                        <CloseButton
                            type="button"
                            onClick={() => {
                                // sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                setSelectedGameUrl(null);
                                onClose();
                            }}
                        >
                            <StyledImage style={{ height: '35px' }} src={purpleX} alt="Close" />
                        </CloseButton>
                    </ModalContent>
                </GameMenuModal>
            )}
        </div>
    );
};

export default ArcadeGame;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    padding: 80px 20px;
`;

const GameMenuModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    position: relative;
    width: 80%;
    height: 50%;
    background: ${setColor.landingBackground};
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const GameIcon = styled.div`
    padding: 20px;
    background: ${setColor.brightPurple};
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &:hover {
        background: ${setColor.iconColor3};
        transform: scale(1.05);
    }
`;

const TZiframeBanner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: black;
    color: purple;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 18px;
    font-weight: bold;
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: 15px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        top: 40px;
        right: 15px;
    }
`;
