import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../../../assets/styles/styles';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import { connectMerchant } from '../api/merchantController';
import { isValidEmail } from '../../../../../utils/generalUtils';

const ConnectAddModal = ({ id, closeAddModal, merchantName, email }) => {
    const [editedEmail, setEditedEmail] = useState(email);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAddClick = async () => {
        const isValid = isValidEmail(editedEmail);
        if (isValid) {
            const resp = await connectMerchant(editedEmail, id);
            if (resp.status === 201) {
                alert(
                    'Email sent to the provided address. Please instruct the merchant to use that link to complete the onboarding process.'
                );
            }
            closeAddModal();
        } else {
            setErrorMessage('Please enter a valid email address.');
        }
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEditedEmail(value);
        if (isValidEmail(value)) {
            setErrorMessage('');
        }
    };

    return (
        <>
            <Typography variant="h2PurpleHeading" fontSize={'22px'} margin={'0 0 0 10px'} color={`${setColor.accent4}`}>
                Create a new Stripe Account for {merchantName}
            </Typography>
            <Card>
                <Typography variant="pWhite" margin={'1rem'}>
                    An email will be sent to the provided address. Please instruct the merchant to use that link to
                    complete the onboarding process.
                </Typography>
                <Typography variant="pWhite" margin={'1rem'}>
                    After verifying or updating merchant's email, proceed with creating the connect account.
                </Typography>

                <Column errorMessage={errorMessage}>
                    {errorMessage && <MessageDesc type="error">{errorMessage}</MessageDesc>}
                    <InputBox type="text" value={editedEmail} onChange={handleEmailChange} />
                </Column>

                <Button
                    variant={'PinkButton'}
                    buttonWidth={'90%'}
                    disabled={errorMessage}
                    label={'Add Stripe Account'}
                    margin={'2rem 0 2rem 2rem'}
                    onClick={handleAddClick}
                />
            </Card>
        </>
    );
};

export default ConnectAddModal;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 2rem;
    height: 50%;
    width: 94%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;
    font-size: 16px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Column = styled.div`
    margin-top: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0')};
    flex: ${(props) => (props.small ? '0.5' : '2')};
    width: ${(props) => (props.small ? '5%' : '95%')};
    @media (max-width: 768px) {
        flex: ${(props) => (props.small ? '1' : '2')};
    }
`;

const MessageDesc = styled.div`
    font-size: 14px;
    margin: 1rem 0 0.5rem 2rem;
    color: ${(props) => (props.type === 'success' ? setColor.successColor : setColor.errorColor)};
`;
