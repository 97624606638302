/* eslint-disable jsx-a11y/media-has-caption */
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';

const FilePreviewer = ({ handleCloseModal, setIsUploaderOn, modalInfo, fileUrl, modalLabels }) => {
    return (
        <FileModal>
            <ModalContainer>
                <HeaderModalContainer>
                    <CloseButton onClick={handleCloseModal}>Close</CloseButton>
                </HeaderModalContainer>
                <ModalInfoContainer>
                    {modalInfo.fileTypeModal === 'image' ? (
                        <ImageContainer>
                            <ImgAdmin src={fileUrl} alt="file-img" />
                        </ImageContainer>
                    ) : (
                        <></>
                    )}
                    {modalInfo.fileTypeModal === 'video' ? (
                        <div>
                            <h2>File is a video</h2>
                            <a href={fileUrl}>Link to video: {fileUrl}</a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {modalLabels.map((label) => {
                        return (
                            <div key={uniqueId()} style={{ display: 'flex', flexDirection: 'row' }}>
                                <b>{label}:</b>
                                <p style={{ marginLeft: '10px' }}>{modalInfo[`${label}`]}</p>
                            </div>
                        );
                    })}
                </ModalInfoContainer>
            </ModalContainer>
        </FileModal>
    );
};

export default FilePreviewer;

const FileModal = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
    left: 800px;
    top: 300px;
    width: 650px;
    height: 650px;
    border-width: 5px;
    border-style: solid;
    border-color: ${setColor.iconColor3};
    background-color: grey;
`;

const HeaderModalContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: end;
`;

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ModalInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
`;

const ImageContainer = styled.div`
    width: 250px;
    min-height: 250px;
    margin: auto;
    margin-bottom: 50px;
    background-color: ${setColor.black};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const CloseButton = styled.button`
    height: 30px;
    align-self: center;
    margin-right: 20px;
`;

const ImgAdmin = styled.img`
    width: 100%;
    margin: auto;
`;
