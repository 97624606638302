import React from 'react';
import styled from 'styled-components';

const FontSelection = ({ selectedFont, onSelectFont }) => {
    const fonts = [
        { name: 'Sans Serif', family: 'Barlow, sans serif' },
        { name: 'Serif', family: 'Times New Roman, serif' },
        { name: 'Hand Writing', family: 'Kalam, cursive' },
    ];

    return (
        <FontSelectionContainer>
            {fonts.map((font, index) => (
                <FontOption key={index} onClick={() => onSelectFont(font.family)}>
                    <FontBox isSelected={selectedFont === font.family}>
                        <p style={{ fontFamily: font.family, fontSize: '44px' }}>Aa</p>
                        <p style={{ fontFamily: font.family, color: '#9DA0A7', fontSize: '14px', fontWeight: 'normal' }}>{font.name}</p>
                    </FontBox>
                </FontOption>
            ))}
        </FontSelectionContainer>
    );
};

export default FontSelection;

const FontSelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem;

    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const FontOption = styled.div`
    cursor: pointer;
    padding: 5px;
    font-family: ${(props) => props.font};

    @media (max-width: 700px) {
        font-size: 12px;
    }
`;
const FontBox = styled.div`
    cursor: pointer;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border: ${({ isSelected }) => (isSelected ? '2px solid #f0153f' : 'none')};
    background: #23173e;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & p {
        font-size: 44px;
        color: white;
    }
`;
