import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import { setColor, setFont } from '../../../../../assets/styles/styles';

const UsersTable = ({ usersData, searchInput }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const ROWS_PER_PAGE = 5;
    const totalPages = Math.ceil(usersData.length / ROWS_PER_PAGE);
    const visibleRows = usersData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const renderRow = (user) => {
        return [
            user.userId,
            `${user.firstName} ${user.lastName}`,
            user.displayName,
            user.email,
            user.role,
            // user.userCountry,
            // user.userBirthday,
            user.createdAt,
        ];
    };

    return (
        <>
            {visibleRows.length !== 0 && (
                <>
                <DataTable
                    headers={['USER ID', 'NAME', 'DISPLAY NAME', 'EMAIL', 'ROLE', 'CREATED AT']}
                    data={visibleRows}
                    renderRow={renderRow}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    isHover={false}
                />
                <Footer>
                    <TableColumnLeft>
                        Total Number of Forms: {usersData.length}
                    </TableColumnLeft>
                    <TableColumnRight>
                        {visibleRows.length !== 0 && (
                            <Pagination>
                                <span>
                                    Page {currentPage} of {totalPages}
                                </span>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </PaginationButton>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </PaginationButton>
                            </Pagination>
                        )}
                    </TableColumnRight>
                </Footer>
                </>
            )}
        </>
    );
};

export default UsersTable;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText}; 
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled}; 
    }
`;