import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { setColor } from '../../../../../assets/styles/styles';
import Typography from '../../../../../components/common/text/Typography';
import { addGameToRoom, updateGameToRoom } from '../api/roomController';
import { ReactComponent as DropdownIcon } from '../../../../../assets/images/icons/Chevron.svg';
import LoadingIcon from '../../../../../components/common/loading/LoadingIcon';
import { default as Button } from '../../../../../components/common/button/Button';
import Banner from '../../../../../components/layout/ui/Banner';
import ToggleSwitch from '../../../../../components/common/switchToggle/ToggleSwitch';

const AddUpdateGames = ({ roomId, action, closeModal, selectedRow }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [url, setUrl] = useState('');
    const [slotNumber, setSlotNumber] = useState('');
    const [clientName, setClientName] = useState('');
    const [type, setType] = useState('');
    const [isTypeOpen, setIsTypeOpen] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState('');
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [displayType, setDisplayType] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disabledAddGame, setDisabledAddGame] = useState(true);
    const [isUpdateActive, setIsUpdateActive] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const types = [
        { id: 'slot', value: 'Slot'},   
    ];

    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleTypeClick = (option) => {
        setIsDropdownOpenType(false);
        setSelectedOptionType(option.id);
        setDisplayType(option.value);
    };

    const AddGameToRoomClick = async () => {
        if(action === 'Add') {
            const data = {
                url,
                slotNumber,
                clientName,
                type: selectedOptionType,
                isActive,
            };
            const resp = await addGameToRoom(roomId, data);
            if (resp?.status === 201) {
                setSuccessMessageBanner('Game added successfully');
                setIsError(false);
                setErrorMessage('');
                setDisabledAddGame(true);
            } else {
                setIsError(true);
                setErrorMessage(resp?.response?.data?.message);
            }
        }

        if(action === 'Update') {
            const data = {
                url,
                slotNumber,
                clientName,
                type: selectedOptionType,
                isActive: isUpdateActive,
            };
            const resp = await updateGameToRoom(selectedRow?.gameId, data);
            if (resp?.status === 200) {
                setSuccessMessageBanner('Game updated successfully');
                setIsError(false);
                setErrorMessage('');
                setDisabledAddGame(true);
            } else {
                setIsError(true);
                setErrorMessage(resp?.response?.data?.message);
            }
        }
    }

    const handleToggleChange = (isChecked) => {
        if(action === 'Add') {
            setIsActive(isChecked);
        }
        setIsUpdateActive(isChecked);
    };

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeModal();
                window.location.reload();
            }, 5000);
            return () => clearTimeout(timer);
        }

        if(url.length === 0 || slotNumber.length === 0 || clientName.length === 0 || selectedOptionType.length === 0) {
            setDisabledAddGame(true);
        } else {
            setDisabledAddGame(false);
        }
    }, [successMessageBanner, url, slotNumber, clientName, selectedOptionType]);

    useEffect(() => {
        if (action === 'Update' && selectedRow) {
            setUrl(selectedRow.url);
            setSlotNumber(selectedRow.slotNumber);
            setClientName(selectedRow.clientName);
            setSelectedOptionType(selectedRow.type);
            setDisplayType(selectedRow.type);
            setIsUpdateActive(selectedRow.isActive);
        }
    }, [selectedRow, action]);

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />
            
            <ModalContent>
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                        {action === 'Add' ? 'Add Game to Room' : 'Update Game'}
                    </Typography>

                    {isLoading && (
                        <LoadingDiv>
                            <LoadingIcon />
                        </LoadingDiv>
                    )}
                    
                    {!isLoading && (
                        <>
                        <Card>
                            <Table>
                                <TableRow>
                                    <TableColumn>
                                        <Label>Room Id</Label>
                                            <InputBox
                                                type="text"
                                                value={roomId}
                                                disabled
                                            /> 
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>URL</Label>
                                        <InputBox
                                            type="text"
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                        /> 
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Slot Number</Label>
                                        <InputBox
                                            type="number"
                                            value={slotNumber}
                                            onChange={(e) => setSlotNumber(e.target.value)}
                                        /> 
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Client Name</Label>
                                        <InputBox
                                            type="text"
                                            value={clientName}
                                            onChange={(e) => setClientName(e.target.value)}
                                        /> 
                                    </TableColumn>
                                </TableRow>
                            
                                <TableRow>
                                    <TableColumn>
                                        <Label>Type</Label>
                                        <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>                                            
                                            <TitleText>
                                                {selectedOptionType || 'Select Type'}
                                            </TitleText>

                                            <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                                            {isDropdownOpenType && (
                                                <DropdownContent>
                                                    {types.map(option => (
                                                        <DropdownItem
                                                            key={option.id}
                                                            onClick={() => handleTypeClick(option)}
                                                            isSelected={selectedOptionType === option.value}
                                                        >
                                                            {option.value}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContent>
                                            )}
                                        </DropdownButton>
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Active</Label>
                                        <ToggleSwitch 
                                            value={action === 'Add' ? isActive : isUpdateActive}
                                            onChange={handleToggleChange} 
                                        />
                                    </TableColumn>
                                </TableRow>
                            
                                {isError && (
                                    <TableRow>
                                        <MessageDesc>
                                            {errorMessage}
                                        </MessageDesc>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <Button
                                        variant={'PinkButton'}
                                        buttonWidth={'100%'}
                                        label={action === 'Add' ? 'Add Game' : 'Update Game'}
                                        disabled={disabledAddGame}
                                        onClick={AddGameToRoomClick}
                                    /> 
                                </TableRow>
                            </Table>
                        </Card>
                        </>
                    )}
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default AddUpdateGames;

const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.isSelected ? `#767676` : `${setColor.inputText}`)};
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
`;
