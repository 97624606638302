import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getSpotifyToken, handleSpotifyAuth } from './api/SpotifyController';
import SpotifyButton from './assets/Spotify_Button_Placeholder.png';
import ConnectedButtonPng from './assets/Connected_Spotify_Button_Placeholder.png';
import { SaveSpotify } from '../../redux/actions/SpotifyActions';
import { fetchSpotify } from '../../redux/slices/spotify';
import { breakpoints } from '../../assets/styles/mediaBreakpoints';
import { setToSessionStorage } from '../../hooks/useSessionStorage';

const Spotify = (props) => {
    const dispatch = useDispatch(); // Get the dispatch function
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const spotify = useSelector(fetchSpotify);

    useEffect(() => {}, []);

    useEffect(() => {
        const existingScript = document.querySelector(`script[src="https://sdk.scdn.co/spotify-player.js"]`);

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://sdk.scdn.co/spotify-player.js';
            script.async = true;
            document.body.appendChild(script);

            window.onSpotifyWebPlaybackSDKReady = () => {};
        }

        if (code) {
            getSpotifyToken(code, urlParams, process.env.REACT_APP_WEB_URL).then((res) => {
                if (res?.accessToken) {
                    getProfile(res.accessToken).then((profile) => {
                        // Grabs profile and check if account is premium
                        if (profile.product === 'free') {
                            props.setSpotifyNotPremiumAccount(true);
                            return;
                        }

                        if (profile.product === 'premium') {
                            setToSessionStorage('spotifyAccessToken', res.accessToken);
                            setToSessionStorage('spotifyRefreshToken', res.refreshToken);

                            dispatch(SaveSpotify(res.accessToken, res.refreshToken));
                        }
                    });
                }
            });
        }
    }, []);

    return (
        <PrimaryButton onClick={() => handleSpotifyAuth(process.env.REACT_APP_WEB_URL)} disabled={spotify.token}>
            <EnterButton src={spotify.token ? ConnectedButtonPng : SpotifyButton} alt={'Spotify'} />
        </PrimaryButton>
    );
};

export default Spotify;

const PrimaryButton = styled.button`
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    z-index: 5;
    cursor: pointer;
`;

const EnterButton = styled.img`
    height: auto;
    width: 250px;
    background-size: cover;
    background-position: center;
    z-index: 8;

    @media (max-width: 960px) {
        width: 225px;
    }
    @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
        width: 180px;
    }
`;
