import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { setColor } from '../../../../../assets/styles/styles';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import Banner from '../../../../../components/layout/ui/Banner';
import { removeMeFromRoom } from '../api/myRoomsController';

const DeleteRoomUserModal = ({ selectedRoomUser, closeDeleteRoomUserModal }) => {
    const navigate = useNavigate();
    const [deleteText, setDeleteText] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [successMessageBanner, setSuccessMessageBanner] = useState('');

    const handleDeleteClick = async () => {
        if (deleteText.toLowerCase() === 'delete') {
            try {
                const response = await removeMeFromRoom(selectedRoomUser.roomId, selectedRoomUser.userId);
                if (response.status === 204) {
                    setSuccessMessageBanner(
                        `User: ${selectedRoomUser.firstName} ${selectedRoomUser.lastName} successfully removed from room the room.`
                    );
                } else if (response.status === 404) {
                    setDeleteError(`Already removed user or not found user ${selectedRoomUser.userId}`);
                } else {
                    setDeleteError(`Failed to remove user ${selectedRoomUser.userId}`);
                }
            } catch (error) {
                setDeleteError(`Something went wrong. Please try again later.`);
            }
        } else {
            setDeleteError('Write DELETE to proceed.');
        }
        setDeleteText('');
    };

    useEffect(() => {
        if (deleteError) {
            const timer = setTimeout(() => {
                setDeleteError('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [deleteError]);

    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeDeleteRoomUserModal();
                // refresh the page
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={'SUCCESS'}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />

            <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                Remove {selectedRoomUser.firstName} {selectedRoomUser.lastName} from the room?
            </Typography>

            <Card>
                <Typography variant="pWhite" margin={'1rem 2rem 0 2rem'}>
                    This action is <YellowText>permanent </YellowText> and <YellowText> irreversible.</YellowText>{' '}
                    Please type DELETE below to proceed.
                </Typography>

                <Typography variant="pWhite" margin={'1rem 2rem 0 2rem'}>
                    <YellowText>
                        This action will remove user from the room and all the data associated with the user will be
                        deleted from this room.
                    </YellowText>
                </Typography>

                {deleteError && <MessageDesc>{deleteError}</MessageDesc>}
                <InputBox type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)} />

                <Button
                    variant={'PinkButton'}
                    buttonWidth={'100%'}
                    disabled={deleteText.toLowerCase() !== 'delete'}
                    label={'REMOVE USER'}
                    margin={'0  0 rem 0'}
                    onClick={handleDeleteClick}
                />
                <Typography variant="GreyText" onClick={closeDeleteRoomUserModal} margin={'1rem 0 0 0'}>
                    GO BACK
                </Typography>
            </Card>
        </>
    );
};

export default DeleteRoomUserModal;

const Card = styled.div`
    height: auto;
    position: relative;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const YellowText = styled.span`
    color: ${setColor.yellowText};
    font-weight: 700;
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 30px 0;
    height: 50%;
    width: 100%;
    background-color: transparent;
`;

const MessageDesc = styled.div`
    font-size: 14px;
    color: ${setColor.errorColor};
    margin: 0rem 0rem 1rem 2rem;
`;
