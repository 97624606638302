import React, { Fragment, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { formatDateTimeWithTimeZone, capitalizeFirstLetter } from '../../../../../utils/generalUtils';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { ReactComponent as Checkmark } from '../../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/icons/Cross.svg';
import { default as Button } from '../../../../../components/common/button/Button';
import Typography from '../../../../../components/common/text/Typography';
import DeleteModal from './DeleteModal';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/images/icons/NotFound.svg';
import Modal from '../../../components/Modal';
import { DEL_ROOM_PERMISSIONS, CACHE_QUERY_OPTIONS } from '../../../../../constants';
import { getPermissionsAndCheckAccess } from '../../../../../api/RolesController';
import { getAllUsers, getRoomTags, getAllGroups, getRoomById, updateRoom, getRoomPrefabs } from '../api/roomController';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import AddRoomUsers from './AddUpdateRoomUsers';
import Dropdown from '../../../../../components/common/inputDropdown/Dropdown';

const RoomsDetails = () => {
    const user = useSelector((state) => state.user);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roomDetails, setRoomDetails] = useState({
        roomName: '',
        editedRoomName: '',
        roomCode: '',
        editedRoomType: '',
        editedOwnerName: '',
        editedThumbnail: '',
        editedOwnerLogo: '',
        editedLogo: '',
        editedSubtitle: '',
        expiry: '',
        description: '',
        ownerId: '',
        roomDetailsImageId: '',
        roomOwnerImageId: '',
        isVisibleInNavMenu: '',
        ticketEventAvailable: '',
        ticketEventEndTime: '',
        merchAvailable: '',
        ownerOnline: '',
        bannerImageExternalUrl: '',
        tags: '',
        groupId: '',
        editedFreeFormJson: '',
        editedRoomThumbnail: '',
        createdDateTime: '',
        updatedDateTime: '',
    });
    const [totalDispDataLength, setTotalDispDataLength] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [label, setLabel] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [fieldsObj, setFieldsObj] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ROWS_PER_PAGE = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [ownerId, setOwnerId] = useState('');
    const [isDropdownOpenOwner, setIsDropdownOpenOwner] = useState(false);
    const [selectedUserOwner, setSelectedUserOwner] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [selectedRT, setSelectedRT] = useState('');
    const [isDropdownOpenRT, setIsDropdownOpenRT] = useState(false);
    const [filteredRT, setFilteredRT] = useState([]);
    const [isDropdownOpenNavMenu, setIsDropdownOpenNavMenu] = useState(false);
    const [selectedNavMenu, setSelectedNavMenu] = useState('');
    const [isDropdownOpenTicketEvent, setIsDropdownOpenTicketEvent] = useState(false);
    const [selectedTkEvnt, setSelectedTkEvnt] = useState('');
    const [isDropdownOpenMerch, setIsDropdownOpenMerch] = useState(false);
    const [selectedMerch, setSelectedMerch] = useState('');
    const [isDropdownOpenOO, setIsDropdownOpenOO] = useState(false);
    const [selectedOO, setSelectedOO] = useState('');
    const [selectedRoomTags, setSelectedRoomTags] = useState([]);
    const [isDropdownOpenGI, setIsDropdownOpenGI] = useState(false);
    const [selectedGI, setSelectedGI] = useState('');
    
    const BooleanOptions = [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' }
    ];
    const {
        data: roomInfo,
        error: roomByIdError,
        isLoading: roomByIdLoading,
        refetch,
    } = useQuery(['roomInfo', id], () => getRoomById(id), {
        enabled: !!id,
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: roomTags } = useQuery(['roomTags'], getRoomTags, {
        ...CACHE_QUERY_OPTIONS(),
    });

    const {
        data: allUsers,
        error: allUsersError,
        isLoading: allUsersLoading,
    } = useQuery(['allUsers'], () => getAllUsers(), {
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: hasPemissionToDelRoom, isLoading: permissionLoading } = useQuery(
        ['permissions', user.role],
        () => getPermissionsAndCheckAccess(user.role, DEL_ROOM_PERMISSIONS),
        {
            enabled: !!user.role,
            ...CACHE_QUERY_OPTIONS(),
        }
    );

    const { data: roomPrefabs } = useQuery(['roomPrefabs'], getRoomPrefabs, {
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: allRoomTags } = useQuery(['roomTags'], getRoomTags, {
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: allGroups } = useQuery(['groups'], getAllGroups, {
        ...CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        if (roomByIdLoading) {
            setIsLoading(true);
        }
    }, [roomByIdLoading]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const fetchData = async (url) => {
        try {
            setIsLoading(true);
            if (roomInfo) {
                const createdDateTime = formatDateTimeWithTimeZone(roomInfo.createdAt);
                const updatedDateTime = formatDateTimeWithTimeZone(roomInfo.updatedAt);
                setSelectedRoomTags(roomInfo.tagsNames);
                setRoomDetails((prevDetails) => ({
                    ...prevDetails,
                    roomName: roomInfo.name,
                    editedRoomName: roomInfo.name,
                    roomCode: roomInfo.code,
                    editedRoomType: roomInfo.roomType,
                    editedOwnerName: roomInfo.ownerName,
                    editedThumbnail: roomInfo.thumbnail,
                    editedOwnerLogo: roomInfo.ownerLogo,
                    editedLogo: roomInfo.logo,
                    editedSubtitle: roomInfo.subtitle,
                    expiry: roomInfo.expiry ? formatDateTimeWithTimeZone(roomInfo.expiry) : '',
                    description: roomInfo.description,
                    ownerId: roomInfo.ownerId,
                    roomDetailsImageId: roomInfo.roomDetailsImageId,
                    roomOwnerImageId: roomInfo.roomOwnerImageId,
                    isVisibleInNavMenu: String(roomInfo.isVisibleInNavMenu),
                    ticketEventAvailable: String(roomInfo.ticketEventAvailable),
                    ticketEventEndTime: roomInfo.ticketEventEndTime ? formatDateTimeWithTimeZone(roomInfo.ticketEventEndTime) : '',
                    merchAvailable: String(roomInfo.merchAvailable),
                    ownerOnline: String(roomInfo.ownerOnline),
                    bannerImageExternalUrl: roomInfo.bannerImageExternalUrl,
                    tags: roomInfo.tags,
                    groupId: roomInfo.userGroup,
                    editedFreeFormJson: JSON.stringify(roomInfo.json),
                    editedRoomThumbnail: roomInfo.roomThumbnail,
                    createdAt: createdDateTime,
                    updatedAt: updatedDateTime,
                }));    

                setTotalDispDataLength(1);
                const fields = [
                    { label: 'Name', key: 'name', value: roomInfo.name, rowNo: 1 },
                    { label: 'Room Type', key: 'roomType', value: roomInfo.roomType, rowNo: 1 },
                    { label: 'Owner ID', key: 'ownerId', value: roomInfo.ownerId, rowNo: 2 },
                    { label: 'Owner Name', key: 'ownerName', value: roomInfo.ownerName, rowNo: 2 },
                    { label: 'Room Code', key: 'code', value: roomInfo.code, rowNo: 3 },
                    { label: 'Subtitle', key: 'subtitle', value: roomInfo.subtitle, rowNo: 3 },
                    { label: 'Expiry', key: 'expiry', value: roomInfo.expiry, rowNo: 4 },
                    { label: 'Description', key: 'description', value: roomInfo.description, rowNo: 4 },
                    {
                        label: 'Is Visible In Nav Menu',
                        key: 'isVisibleInNavMenu',
                        value: String(roomInfo.isVisibleInNavMenu),
                        rowNo: 5,
                    },
                    {
                        label: 'Ticket Event Available',
                        key: 'ticketEventAvailable',
                        value: String(roomInfo.ticketEventAvailable),
                        rowNo: 5,
                    },
                    {
                        label: 'Ticket Event End Time',
                        key: 'ticketEventEndTime',
                        value: roomInfo.ticketEventEndTime,
                        rowNo: 6,
                    },
                    {
                        label: 'Merch Available',
                        key: 'merchAvailable',
                        value: String(roomInfo.merchAvailable),
                        rowNo: 6,
                    },
                    { label: 'Owner Online', key: 'ownerOnline', value: String(roomInfo.ownerOnline), rowNo: 7 },
                    { label: 'Tags', key: 'tags', value: roomInfo.tags, rowNo: 7 },
                    { label: 'Group ID', key: 'groupId', value: roomInfo.groupId, rowNo: 8 },
                    { label: 'Free Form Json', key: 'json', value: JSON.stringify(roomInfo.json), rowNo: 8 },
                    // { label: 'Thumbnail', key: 'thumbnail', value: roomInfo.thumbnail, rowNo: 9 },
                    { label: 'Owner Logo', key: 'ownerLogo', value: roomInfo.ownerLogo, rowNo: 9 },
                    // { label: 'Logo', key: 'logo', value: roomInfo.logo, rowNo: 10 },
                    { label: 'Room Details Image ID',
                        key: 'roomDetailsImageId',
                        value: roomInfo.roomDetailsImageId,
                        rowNo: 9,
                    },
                    {
                        label: 'Room Owner Image ID',
                        key: 'roomOwnerImageId',
                        value: roomInfo.roomOwnerImageId,
                        rowNo: 11,
                    },
                    {
                        label: 'Banner Image External Url',
                        key: 'bannerImageExternalUrl',
                        value: roomInfo.bannerImageExternalUrl,
                        rowNo: 11,
                    },
                ];
                setFieldsObj(fields);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setTotalDispDataLength(0);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (roomInfo) {
            fetchData();
        }
    }, [roomInfo]);

    const handleCloseClick = async (label) => {
        if (hasChanges) {
            setErrorMessage(`Changes not saved for ${label.toLowerCase()}`);
            setHasChanges(false);
        }
        setLabel('');
        setErrorMessage('');
        setSuccessMessage('');
        setIsDropdownOpenNavMenu(false);
        setIsDropdownOpenOwner(false);
        setIsDropdownOpenRT(false);
        setIsDropdownOpenTicketEvent(false);
        setIsDropdownOpenMerch(false);
        setIsDropdownOpenOO(false);
        setIsDropdownOpenGI(false);
        await fetchData();
    };

    const handleCheckmarkClick = async (label) => {
        setLabel(label);
        if (!hasChanges) {
            setErrorMessage(`No changes to update for ${label.toLowerCase()}`);
        } else {
            setErrorMessage('');
        }
        const updateBody = {};
        const fieldToUpdate = fieldsObj.find((field) => field.key === label);

        if (fieldToUpdate && hasChanges) {
            const fieldName = fieldToUpdate.key;
            const fieldValue = getFieldValue(fieldToUpdate);
            updateBody[fieldName] = fieldValue;
        }
        if (fieldToUpdate.key === 'tags') {
            updateBody.tags = [selectedRoomTags.map((tag) => tag.id).join(',')];
        }

        if (fieldToUpdate.key === 'ownerId') {
            updateBody.ownerName = ownerName;
        }

        if (Object.keys(updateBody).length !== 0) {
            try {
                if(updateBody.roomType) {
                    const roomTypeExists = roomPrefabs.filter((room) => room.roomType.toLowerCase() === updateBody.roomType.toLowerCase());
                    if(roomTypeExists.length === 0) {
                        setErrorMessage(`Room type does not exist. Please select from the dropdown`);
                        return;
                    }
                }

                if(updateBody.ownerId) {
                    const userExists = allUsers?.filter((user) => user.email.toLowerCase() === updateBody.ownerId.toLowerCase());
                    if(userExists.length === 0) {
                        setErrorMessage(`User does not exist. Please select from the dropdown`);
                        return;
                    }
                }

                if(updateBody.groupId) {
                    const groupExists = allGroups.filter((group) => group.level.toLowerCase() === updateBody.groupId.toLowerCase());
                    if(groupExists.length === 0) {
                        setErrorMessage(`Group does not exist. Please select from the dropdown`);
                        return;
                    }
                    updateBody.groupId = groupExists[0].id;
                }

                if(updateBody.isVisibleInNavMenu) {
                    const navMenuExists = BooleanOptions.filter((option) => option.value.toLowerCase() === updateBody.isVisibleInNavMenu.toLowerCase());
                    if(navMenuExists.length === 0) {
                        setErrorMessage(`Accepting only boolean values. Please select from the dropdown`);
                        return;
                    }
                }

                if(updateBody.ticketEventAvailable) {
                    const navMenuExists = BooleanOptions.filter((option) => option.value.toLowerCase() === updateBody.ticketEventAvailable.toLowerCase());
                    if(navMenuExists.length === 0) {
                        setErrorMessage(`Accepting only boolean values. Please select from the dropdown`);
                        return;
                    }
                }

                if(updateBody.merchAvailable) {
                    const navMenuExists = BooleanOptions.filter((option) => option.value.toLowerCase() === updateBody.merchAvailable.toLowerCase());
                    if(navMenuExists.length === 0) {
                        setErrorMessage(`Accepting only boolean values. Please select from the dropdown`);
                        return;
                    }
                }

                if(updateBody.ownerOnline) {
                    const navMenuExists = BooleanOptions.filter((option) => option.value.toLowerCase() === updateBody.ownerOnline.toLowerCase());
                    if(navMenuExists.length === 0) {
                        setErrorMessage(`Accepting only boolean values. Please select from the dropdown`);
                        return;
                    }
                }
                const response = await updateRoom(id, updateBody);

                if (response.status === 200) {
                    setSuccessMessage(`Successfully updated ${label.toLowerCase()}`);
                } else {
                    setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                }
                setHasChanges(false);
                refetch();
            } catch (error) {
                console.error('Error updating data:', error);
                setErrorMessage(`Failed to update ${label.toLowerCase()}`);
            }
        }
    };

    useEffect(() => {
        if (errorMessage || successMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setSuccessMessage('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [errorMessage, successMessage]);

    const handleBack = () => {
        navigate(`/console/rooms`);
    };

    const handleUserClickOwner = (option) => {
        setSelectedUserOwner(option.userId);
        setIsDropdownOpenOwner(false);
        setOwnerId(option.userId);
        setOwnerName(option.displayName);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            ownerId: option.userId,
            ownerName: option.displayName,
        }));
    };

    const handleClickNavMenu = (option) => {
        setSelectedNavMenu(option.value);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            isVisibleInNavMenu: option.value,
        }));
        setIsDropdownOpenNavMenu(false);
    };

    const handleClickTktEvent = (option) => {
        setSelectedTkEvnt(option.value);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            ticketEventAvailable: option.value,
        }));
        setIsDropdownOpenTicketEvent(false);
    };

    const handleClickRT = (option) => {
        setSelectedRT(option.roomType);
        roomDetails.editedRoomType = option.roomType;
        setIsDropdownOpenRT(false);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            roomType: option.roomType,
        }));
    };

    const handleClickMerch = (option) => {
        setSelectedMerch(option.value);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            merchAvailable: option.value,
        }));
        setIsDropdownOpenMerch(false);
    };

    const handleClickOO = (option) => {
        setSelectedOO(option.value);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            ownerOnline: option.value,
        }));
        setIsDropdownOpenOO(false);
    };

    const handleClickGI = (option) => {
        setSelectedGI(option.level);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            groupId: option.level,
        }));
        setIsDropdownOpenGI(false);
    };

    const handleSettingsClick = () => {
        navigate(`/console/rooms/${id}/settings`);
    };

    const handleSubRoomsClick = () => {
        navigate(`/console/rooms/${id}/subrooms`);
    };

    const handleRoomUsersClick = () => {
        navigate(`/console/rooms/${id}/users`);
    };

    const handleGuestBookClick = () => {
        navigate(`/console/rooms/${id}/guest-book`);
    };

    const handleCasinoClick = () => {
        navigate(`/console/rooms/${id}/casino-games`);
    };

    const getFieldValue = (field) => {
        switch (field.key) {
            case 'name':
                return roomDetails.editedRoomName || '';
            case 'roomType':
                return roomDetails.editedRoomType || '';
            case 'code':
                return roomDetails.roomCode || '';
            case 'ownerName':
                return roomDetails.editedOwnerName || '';
            case 'thumbnail':
                return roomDetails.editedThumbnail || '';
            case 'ownerLogo':
                return roomDetails.editedOwnerLogo || '';
            case 'logo':
                return roomDetails.editedLogo || '';
            case 'subtitle':
                return roomDetails.editedSubtitle || '';
            case 'expiry':
                return roomDetails.expiry || '';
            case 'description':
                return roomDetails.description || '';
            case 'ownerId':
                return roomDetails.ownerId || '';
            case 'roomDetailsImageId':
                return roomDetails.roomDetailsImageId || '';
            case 'roomOwnerImageId':
                return roomDetails.roomOwnerImageId || '';
            case 'isVisibleInNavMenu':
                return roomDetails.isVisibleInNavMenu || String(false);
            case 'imageTrendingId':
                return roomDetails.imageTrendingId || '';
            case 'imageCommunityId':
                return roomDetails.imageCommunityId || '';
            case 'imageExploreId':
                return roomDetails.imageExploreId || '';
            case 'ticketEventAvailable':
                return roomDetails.ticketEventAvailable || String(false);
            case 'ticketEventEndTime':
                return roomDetails.ticketEventEndTime || '';
            case 'merchAvailable':
                return roomDetails.merchAvailable || String(false);
            case 'ownerOnline':
                return roomDetails.ownerOnline || String(false);
            case 'bannerImageExternalUrl':
                return roomDetails.bannerImageExternalUrl || '';
            case 'tags':
                return roomDetails.tags || '';
            case 'groupId':
                return roomDetails.groupId || '';
            case 'json':
                return roomDetails.editedFreeFormJson || '';
            case 'roomThumbnail':
                return roomDetails.editedRoomThumbnail || '';
            default:
                return '';
        }
    };  

    const handleFieldChange = (e, field) => {
        setHasChanges(true);
        if (field.key === 'name') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedRoomName: e.target.value,
            }));
        }
        if (field.key === 'roomType') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedRoomType: e.target.value,
            }));
            const filtered = roomPrefabs.filter((room) => room.roomType.toLowerCase().includes(selectedRT.toLowerCase()));
            setFilteredRT(filtered);
            setIsDropdownOpenRT(true);
        }
        if (field.key === 'thumbnail') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedThumbnail: e.target.value,
            }));
        }
        if (field.key === 'ownerLogo') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedOwnerLogo: e.target.value,
            }));
        }
        if (field.key === 'logo') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedLogo: e.target.value,
            }));
        }
        if (field.key === 'subtitle') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedSubtitle: e.target.value,
            }));
        }
        if (field.key === 'description') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                description: e.target.value,
            }));
        }
        if (field.key === 'ownerId') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                ownerId: e.target.value,
            }));
            setOwnerId(e.target.value);
            setIsDropdownOpenOwner(true);
        }
        if (field.key === 'roomDetailsImageId') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                roomDetailsImageId: e.target.value,
            }));
        }
        if (field.key === 'roomOwnerImageId') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                roomOwnerImageId: e.target.value,
            }));
        }
        if (field.key === 'isVisibleInNavMenu') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                isVisibleInNavMenu: e.target.value,
            }));
            setIsDropdownOpenNavMenu(true);
        }
        if (field.key === 'ticketEventAvailable') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                ticketEventAvailable: e.target.value,
            }));
            setIsDropdownOpenTicketEvent(true);
        }
        if (field.key === 'merchAvailable') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                merchAvailable: e.target.value,
            }));
            setIsDropdownOpenMerch(true);
        }
        if (field.key === 'ownerOnline') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                ownerOnline: e.target.value,
            }));
            setIsDropdownOpenOO(true);
        }
        if(field.key === 'groupId') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                groupId: e.target.value,
            }));
            setIsDropdownOpenGI(true);
        }
        if (field.key === 'bannerImageExternalUrl') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                bannerImageExternalUrl: e.target.value,
            }));
        }
        if (field.key === 'json') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedFreeFormJson: e.target.value,
            }));
        }
        if (field.key === 'roomThumbnail') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                editedRoomThumbnail: e.target.value,
            }));
        }
        if(field.key === 'code') {
            setRoomDetails((prevDetails) => ({
                ...prevDetails,
                roomCode: e.target.value,
            }));
        }
    };
    const handleDateChange = (date) => {
        setHasChanges(true);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            expiry: date,
        }));
    };
    const handleDateticketEventEndTimeChange = (date) => {
        setHasChanges(true);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            ticketEventEndTime: date,
        }));
    };

    const handleRoomTagsClick = (option) => {
        setHasChanges(true);
        setSelectedRoomTags([...selectedRoomTags, option]);
        setRoomDetails((prevDetails) => ({
            ...prevDetails,
            tags: selectedRoomTags.map((tag) => tag.value).join(','),
        }));
    };

    const handleRemoveTag = (tag) => {
        setHasChanges(true);
        setSelectedRoomTags(selectedRoomTags.filter((selectedTag) => selectedTag.value !== tag.value));
    };

    const handleDropDowns = (e) => {
        if(e.target.id === 'ownerId') {
            setIsDropdownOpenOwner(!isDropdownOpenOwner);
        }
        if(e.target.id === 'roomType') {
            setIsDropdownOpenRT(!isDropdownOpenRT);
        }
        if(e.target.id === 'isVisibleInNavMenu') {
            setIsDropdownOpenNavMenu(!isDropdownOpenNavMenu);
        }
        if(e.target.id === 'ticketEventAvailable') {
            setIsDropdownOpenTicketEvent(!isDropdownOpenTicketEvent);
        }
        if(e.target.id === 'merchAvailable') {
            setIsDropdownOpenMerch(!isDropdownOpenMerch);
        }
        if(e.target.id === 'ownerOnline') {
            setIsDropdownOpenOO(!isDropdownOpenOO);
        }
        if(e.target.id === 'groupId') {
            setIsDropdownOpenGI(!isDropdownOpenGI);
        }
    };

    const uniqueRowNumbers = [...new Set(fieldsObj.map((field) => field.rowNo))];

    return (
        <>
            <Title
                title={'ROOM OVERVIEW'}
                description={
                    'Manage all rooms in  Intraverse. Changes made here are automatically pushed to Production.'
                }
            />
            <UserConsoleText style={{ marginBottom: '1rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            <Body isLoading={isLoading}>
                <>
                    {roomInfo && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{roomInfo.id}</Typography>
                                    </TableColumn>

                                    <TableColumnButton>
                                        <Button
                                            variant={'SmallButton'}
                                            label="SETTINGS"
                                            buttonWidth="250px"
                                            margin="0rem 1rem 0rem 0rem"
                                            onClick={handleSettingsClick}
                                            border={`${setColor.accent4}`}
                                        />

                                        <Button
                                            variant={'SmallButton'}
                                            label="ROOM USERS"
                                            buttonWidth="250px"
                                            margin="0rem 1rem 0rem 0rem"
                                            onClick={handleRoomUsersClick}
                                            border={`${setColor.accent4}`}
                                        />
                                        <Button
                                            variant={'SmallButton'}
                                            label="GUESTBOOK"
                                            buttonWidth="250px"
                                            margin="0rem 1rem 0rem 0rem"
                                            onClick={handleGuestBookClick}
                                            border={`${setColor.accent4}`}
                                        />
                                        <Button
                                            variant={'SmallButton'}
                                            label="RELATED ROOMS"
                                            buttonWidth="250px"
                                            margin="0rem 1rem 0rem 0rem"
                                            onClick={handleSubRoomsClick}
                                            border={`${setColor.accent4}`}
                                        />
                                        {hasPemissionToDelRoom && (
                                            <Button
                                                variant={'SmallButton'}
                                                label="DELETE ROOM"
                                                buttonWidth="250px"
                                                onClick={() => openModal()}
                                                border={`${setColor.accent4}`}
                                            />
                                        )}
                                    </TableColumnButton>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <strong>CREATED:</strong> {roomInfo.createdAt}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <strong>UPDATED:</strong> {roomInfo.updatedAt}
                                    </TableColumn>
                                    <TableColumnButton>
                                        <Button
                                                variant={'SmallButton'}
                                                label="GAMES"
                                                margin="0.5rem 0rem 0rem 0rem"
                                                onClick={handleCasinoClick}
                                                border={`${setColor.accent4}`}
                                            />
                                    </TableColumnButton>
                                </TableRow>
                            </CardTable>

                            <Table>
                                {uniqueRowNumbers.map((rowNo) => (
                                    <Row key={rowNo}>
                                        {fieldsObj
                                            .filter((field) => field.rowNo === rowNo)
                                            .map((field) => (
                                                <React.Fragment key={field.key}>
                                                    <StyledFieldContainer>
                                                        <Column errorMessage={errorMessage}>
                                                            <Label>{field.label}</Label>
                                                            {label === field.key && errorMessage && (
                                                                <MessageDesc type="error">{errorMessage}</MessageDesc>
                                                            )}
                                                            {label === field.key && successMessage && (
                                                                <MessageDesc type="success">{successMessage}</MessageDesc>
                                                            )}
                                                            {field.key !== 'expiry' && field.key !== 'ticketEventEndTime' && field.key !== 'tags' && (
                                                                <InputContainer>
                                                                    <InputBox
                                                                        type="text"
                                                                        onClick={(e) => handleDropDowns(e, field)}
                                                                        id={field.key}
                                                                        value={getFieldValue(field)}
                                                                        onChange={(e) => handleFieldChange(e, field)}
                                                                    />
                                                                    {field.key === 'ownerId' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenOwner} />
                                                                    )}
                                                                    {field.key === 'roomType' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenRT} />
                                                                    )}
                                                                    {field.key === 'isVisibleInNavMenu' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenNavMenu} />
                                                                    )}
                                                                    {field.key === 'ticketEventAvailable' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenTicketEvent} />
                                                                    )}
                                                                    {field.key === 'merchAvailable' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenMerch} />
                                                                    )}
                                                                    {field.key === 'ownerOnline' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenOO} />
                                                                    )}
                                                                    {field.key === 'groupId' && (
                                                                        <StyledDropdownIcon isopen={isDropdownOpenGI} />
                                                                    )}
                                                                </InputContainer>
                                                            )}

                                                            {field.key === 'ownerId' && (
                                                                <>
                                                                    {isDropdownOpenOwner && (
                                                                        <>
                                                                        <DropdownContentUsers>
                                                                            {allUsers && allUsers?.length > 0 && allUsers.map((option) => (
                                                                                <>
                                                                                <DropdownItem
                                                                                    key={option.userId}
                                                                                    onClick={() =>
                                                                                        handleUserClickOwner(option)
                                                                                    }
                                                                                    isSelected={
                                                                                        selectedUserOwner === option.email
                                                                                    }
                                                                                >
                                                                                    {option.email}
                                                                                </DropdownItem>
                                                                                </>
                                                                            ))}
                                                                        </DropdownContentUsers>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
    
                                                            {field.key === 'roomType' && (
                                                                <>
                                                                    {isDropdownOpenRT && (
                                                                        <DropdownContentUsers>
                                                                            {roomPrefabs.length > 0 && roomPrefabs.map((option) => (
                                                                                <>
                                                                                <DropdownItem
                                                                                    key={option.userId}
                                                                                    onClick={() =>
                                                                                        handleClickRT(option)
                                                                                    }
                                                                                    isSelected={
                                                                                        selectedRT === option.roomType
                                                                                    }
                                                                                >
                                                                                    {option.roomType}
                                                                                </DropdownItem>
                                                                                </>
                                                                            ))}
                                                                        </DropdownContentUsers>
                                                                    )}
                                                                </>
                                                            )}

                                                            {field.key === 'isVisibleInNavMenu' && (
                                                                <>
                                                                {isDropdownOpenNavMenu && (
                                                                    <DropdownContentUsers>
                                                                        {BooleanOptions.length > 0 && BooleanOptions.map((option) => (
                                                                            <>
                                                                            <DropdownItem
                                                                                key={option.value}
                                                                                onClick={() =>
                                                                                    handleClickNavMenu(option)
                                                                                }
                                                                                isSelected={
                                                                                    selectedNavMenu === option.value
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </DropdownItem>
                                                                            </>
                                                                        ))}
                                                                    </DropdownContentUsers>
                                                                )}
                                                                </>
                                                            )}

                                                            {field.key === 'ticketEventAvailable' && (
                                                                <>
                                                                {isDropdownOpenTicketEvent && (
                                                                    <DropdownContentUsers>
                                                                        {BooleanOptions.length > 0 && BooleanOptions.map((option) => (
                                                                            <>
                                                                            <DropdownItem
                                                                                key={option.value}
                                                                                onClick={() =>
                                                                                    handleClickTktEvent(option)
                                                                                }
                                                                                isSelected={
                                                                                    selectedTkEvnt === option.value
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </DropdownItem>
                                                                            </>
                                                                        ))}
                                                                    </DropdownContentUsers>
                                                                )}
                                                                </>
                                                            )}

                                                            {field.key === 'merchAvailable' && (
                                                                <>
                                                                {isDropdownOpenMerch && (
                                                                    <DropdownContentUsers>
                                                                        {BooleanOptions.length > 0 && BooleanOptions.map((option) => (
                                                                            <>
                                                                            <DropdownItem
                                                                                key={option.value}
                                                                                onClick={() =>
                                                                                    handleClickMerch(option)
                                                                                }
                                                                                isSelected={
                                                                                    selectedMerch === option.value
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </DropdownItem>
                                                                            </>
                                                                        ))}
                                                                    </DropdownContentUsers>
                                                                )}
                                                                </>
                                                            )}

                                                            {field.key === 'ownerOnline' && (
                                                                <>
                                                                {isDropdownOpenOO && (
                                                                    <DropdownContentUsers>
                                                                        {BooleanOptions.length > 0 && BooleanOptions.map((option) => (
                                                                            <>
                                                                            <DropdownItem
                                                                                key={option.value}
                                                                                onClick={() =>
                                                                                    handleClickOO(option)
                                                                                }
                                                                                isSelected={
                                                                                    selectedOO === option.value
                                                                                }
                                                                            >
                                                                                {option.label}
                                                                            </DropdownItem>
                                                                            </>
                                                                        ))}
                                                                    </DropdownContentUsers>
                                                                )}
                                                                </>
                                                            )}

                                                            {field.key === 'expiry' && (
                                                                <StyledBirthdaySelector>
                                                                    <DateInput 
                                                                        selected={getFieldValue(field) ? new Date(getFieldValue(field)) : ''}
                                                                        value={getFieldValue(field)}
                                                                        onChange={handleDateChange}
                                                                        showTimeSelect
                                                                        dateFormat="Pp"
                                                                    />
                                                                </StyledBirthdaySelector>
                                                            )}

                                                            {field.key === 'ticketEventEndTime' && (
                                                                <StyledBirthdaySelector>
                                                                    <DateInput
                                                                        selected={getFieldValue(field) ? new Date(getFieldValue(field)) : ''}
                                                                        value={getFieldValue(field)}
                                                                        onChange={handleDateticketEventEndTimeChange}
                                                                        showTimeSelect
                                                                        dateFormat="Pp"
                                                                    />
                                                                </StyledBirthdaySelector>
                                                            )}

                                                            {field.key === 'tags' && (
                                                                <>
                                                                <Dropdown
                                                                    key={'roomTagsInput'}
                                                                    items={allRoomTags?.map((item) => ({
                                                                        ...item,
                                                                        value: item.label,
                                                                    }))}
                                                                    selectedValue={selectedRoomTags}
                                                                    setSelectedValue={handleRoomTagsClick}
                                                                    placeholder="Select Room Tags"
                                                                    tags={roomInfo.tagsNames}
                                                                    removeTags={handleRemoveTag}
                                                                />
                                                                </>
                                                            )}

                                                            {field.key === 'groupId' && (
                                                                <>
                                                                {isDropdownOpenGI && (
                                                                    <DropdownContentUsers>
                                                                        {allGroups.length > 0 && allGroups.map((option) => (
                                                                            <>
                                                                            <DropdownItem
                                                                                key={option.id}
                                                                                onClick={() =>
                                                                                    handleClickGI(option)
                                                                                }
                                                                                isSelected={
                                                                                    selectedGI === option.level
                                                                                }
                                                                            >
                                                                                {option.level}
                                                                            </DropdownItem>
                                                                            </>
                                                                        ))}
                                                                    </DropdownContentUsers>
                                                                )}
                                                                </>
                                                            )}
                                                        </Column>
                                                    </StyledFieldContainer>
                                                        <Column small>
                                                            <IconDiv>
                                                                {field.key !== 'ownerName' && (
                                                                    <>
                                                                    <StyledCheckmark
                                                                        onClick={() => handleCheckmarkClick(field.key)}
                                                                    />
                                                                    <StyledCloseIcon
                                                                        onClick={() => handleCloseClick(field.key)}
                                                                    />
                                                                    </>
                                                                )}
                                                            </IconDiv>
                                                        </Column>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                ))}
                            </Table>
                        </>
                    )}
                    {totalDispDataLength === 0 && <NotFoundImage alt="Not Found" />}
                </>
            </Body>
        </>
    );
};

export default RoomsDetails;

const UserConsoleText = styled.h1`
    font-family: ${setFont.barlowCondensed};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const IconDiv = styled.div`
    justify-content: center;
    align-items: center;
    margin: 2.5rem 2rem 2rem 1rem;
    width: fit-content;
    color: ${setColor.accent4};
    justify-content: flex-end;
`;

const StyledCheckmark = styled(Checkmark)`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.greenLight};
        }
    }
    @media (max-width: 740px) {
        margin-left: -1rem;
    }
    @media (max-width: 975px) {
        margin-left: 1rem;
    }
    @media (max-width: 775px) {
        margin-left: -1rem;
    }
    @media (max-width: 450px) {
        width: 15px;
        height: 15px;
    }
`;

const StyledCloseIcon = styled(Cross)`
    border-radius: 5px !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.lightPink};
        }
    }
    @media (max-width: 740px) {
        margin-left: -1rem;
    }
    @media (max-width: 975px) {
        margin-left: 1rem;
    }
    @media (max-width: 775px) {
        margin-left: -1rem;
    }
    @media (max-width: 450px) {
        width: 15px;
        height: 15px;
        margin-left: -0.9rem;
    }
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const StyledFieldContainer = styled.div`
    width: 40%;
    margin-right: -2rem;
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0rem;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    display: flex;
    width: 50%;
    flex: 1;
    flex-wrap: 1;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
        width: 100%;
        justify-content: flex-start;
        margin: 1rem 1rem;
    }
`;

const Table = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
`;

const Row = styled.div`
    display: flex;
`;

const Column = styled.div`
    margin-top: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0')};
    flex: ${(props) => (props.small ? '0.5' : '2')};
    width: ${(props) => (props.small ? '5%' : '95%')};
    @media (max-width: 768px) {
        flex: ${(props) => (props.small ? '1' : '2')};
    }
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const DropdownContentUsers = styled.div`
    position: realtive;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const MessageDesc = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => (props.type === 'success' ? setColor.successColor : setColor.errorColor)};
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const DateInput = styled(DatePicker)`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${setColor.white};
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const StyledBirthdaySelector = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledDropdownIcon = styled(ChevronIcon)`
    position: absolute;
    top: 35%;
    right: 10px;
    transform: ${(props) => (props.isopen ? 'rotate(180deg)' : 'rotate(0deg)')};
    font-size: 1.2rem;
    color: ${setColor.inputText};
    pointer-events: none;
`;
const InputContainer = styled.div`
    position: relative;
`;