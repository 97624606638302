import { API_GET } from '../../../../../config/axiosConfig';

export async function getAllUsers() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users`;
        const respAxios = await API_GET(url);
        if (respAxios?.status === 200) {
            return respAxios?.data?.data;
        }
        return [];
    } catch (err) {
        console.error('Error fetching data:', err);
        return [];
    }
}