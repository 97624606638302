import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import { ReactComponent as CheckmarkIcon } from '../../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/icons/Cross.svg';
import { ReactComponent as NullIcon } from '../../../../../assets/images/icons/System.svg';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import { ReactComponent as ViewQueIcon } from '../../../../../assets/images/icons/questionIcon.svg';
import { getAllFormTypes, getFormStyleTypes } from '../api/surveysController';
import Modal from '../../../components/Modal';
import DeleteModal from './DeleteModal';
import { DEFAULT_QUERY_OPTIONS } from '../../../../../constants';

const SurveysTable = ({ surveysData, totalNumber }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [optionsData, setOptionsData] = useState([]);
    const [styleOptionsData, setStyleOptionsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const ROWS_PER_PAGE = 5;
    const totalPages = Math.ceil(totalNumber / ROWS_PER_PAGE);
    const visibleRows = surveysData.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE);

    const { data: formTypesData, error, isLoading } = 
        useQuery(['formTypes'],
        getAllFormTypes(),
        {
            ...DEFAULT_QUERY_OPTIONS(300000), 
        }
    );
    
    const { data: formStyleTypesData, error: formStyleTypesErr, isLoading: formStyleErrLoading } = 
        useQuery(['formStyleTypes'],
            getFormStyleTypes(),
        {
            ...DEFAULT_QUERY_OPTIONS(300000),  
        }
    );

    useEffect(() => {
        if(formTypesData && formTypesData.length > 0) {
            const makeDropDownData = formTypesData.map((item) => {
                return {
                    value: item.id,
                    label: item.formTypeName
                };
            });
            setOptionsData(makeDropDownData);
        }

        if(formStyleTypesData && formStyleTypesData.length > 0) {
            const makeDropDownData = formStyleTypesData.map((item) => {
                return {
                    value: item.id,
                    label: item.styleTypeName
                };
            });
            setStyleOptionsData(makeDropDownData);
        }
    }, [formTypesData, formStyleTypesData]);

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setIsModalDeleteOpen(true);
    }

    const handleViewQueIcon = (row) => {
        navigate(`/console/surveys/${row.id}/questions`);
    }

    const renderRow = (survey) => {
        let anonymousIcon;
        let mustFinishIcon;
        let previousQuestionAllowedIcon;

        if (survey.anonymous === true) {
            anonymousIcon = <StyledCheckmark />;
        } else if (survey.status === false) {
            anonymousIcon = <StyledCloseIcon />;
        } else {
            anonymousIcon = <StyledNullIcon />;
        }

        if (survey.previousQuestionAllowed === true) {
            previousQuestionAllowedIcon = <StyledCheckmark />;
        } else if (survey.previousQuestionAllowed === false) {
            previousQuestionAllowedIcon = <StyledCloseIcon />;
        } else {
            previousQuestionAllowedIcon = <StyledNullIcon />;
        }

        if (survey.mustFinish === true) {
            mustFinishIcon = <StyledCheckmark />;
        } else if (survey.mustFinish === false) {
            mustFinishIcon = <StyledCloseIcon />;
        } else {
            mustFinishIcon = <StyledNullIcon />;
        }

        optionsData.forEach((item) => {
            if (survey.formTypeId === item.value) {
                survey.formTypeId = `${item.value}-${item.label}`;
            }
        });

        styleOptionsData.forEach((item) => {
            if (survey.styleTypeId === item.value) {
                survey.styleTypeId = `${item.value}-${item.label}`;
            }
        });

        const buttonEdit = (
            <StyledDeleteIcon onClick={() => handleDeleteClick(survey)} />
        );
        
        const buttonQue = (
            <StyledViewQueIcon onClick={() => handleViewQueIcon(survey)} />
        );

        return [
            survey.id,
            survey.label || 'N/A', 
            survey.title || 'N/A',
            survey.description || 'N/A',
            survey.createdAt,
            survey.formTypeId || 'N/A',
            survey.styleTypeId || 'N/A',
            <>{mustFinishIcon}</>,
            <>{anonymousIcon}</>,
            <>{previousQuestionAllowedIcon}</>,
            <>{buttonQue}</>,
            <>{buttonEdit}</>,
        ];
    };

    return (
        <>
            {surveysData.length !== 0 && totalNumber !== 0 && (
                <>
                <DataTable
                    headers={['SURVEY ID', 'LABEL', 'TITLE', 'DESCRIPTION', 'CREATION TIME', 'TYPE', 'STYLE', 'MUST FINISH', 'ANONYMOUS', 'PREVIOUS QUESTION ALLOWED', '', '']}
                    data={visibleRows}
                    renderRow={renderRow}
                    openModal={openModal}
                    isModalOpen={isModalOpen}
                    selectedRow={selectedRow}
                    isHover={false}
                />
                <Footer>
                    <TableColumnLeft>
                        Total Number of Forms: {totalNumber}
                    </TableColumnLeft>
                    <TableColumnRight>
                        {visibleRows.length !== 0 && (
                            <Pagination>
                                <span>
                                    Page {currentPage} of {totalPages}
                                </span>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </PaginationButton>
                                <PaginationButton
                                    onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </PaginationButton>
                            </Pagination>
                        )}
                    </TableColumnRight>
                </Footer>
                </>
            )}

            {isModalDeleteOpen && (
                <Modal
                    isOpen={isModalDeleteOpen}
                    setIsOpen={setIsModalDeleteOpen}
                    children={
                        <DeleteModal
                            surveyId={selectedRow.id}
                            questionId=""
                            optionId=""
                            name={selectedRow.title} 
                            requestType="survey"
                            closeDeleteModal={() => setIsModalDeleteOpen(false)}
                        />
                    }
                />
            )}
        </>
    );
};

export default SurveysTable;

const StyledCheckmark = styled(CheckmarkIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: #93ebc7;
    }
`;

const StyledCloseIcon = styled(Cross)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
    #Union {
        fill: ${setColor.spanTextColor};
    }
`;

const StyledNullIcon = styled(NullIcon)`
    width: 12px;
    height: 12px;
    margin: 1.5rem;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledViewQueIcon = styled(ViewQueIcon)`
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText}; 
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled}; 
    }
`;