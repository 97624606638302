import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DEFAULT_QUERY_OPTIONS } from '../../constants';
import { getServer } from './renderStreamController';

const RenderStreaming = () => {
    const { roomName } = useParams();
    const userId = useSelector((state) => state.user?.userId);
    const token = useSelector((state) => state?.user?.token);
    const currentUrl = window.location.href;
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const { data: serverInfo, error: serverErr, isLoading: serverLoading } = useQuery(
        ['serverInfo', token, userId, currentUrl],
        () => getServer(token, userId, currentUrl),
        {
            enabled: !!token && !!userId && !!currentUrl && !iframeLoaded, // Fetch only if not already loaded
            ...DEFAULT_QUERY_OPTIONS(), 
        }
    );

    // make it based on the environment
    const env = process.env.REACT_APP_STREAM_API_ENV;
    const stageMap = {
        production: 'production',
        staging: 'staging',
        development: 'dev',
        demo: 'demo',
    };

    useEffect(() => {
        console.log('serverInfo', serverInfo);
        if (serverInfo && (serverInfo?.status === 200 || serverInfo?.status === 201) && !iframeLoaded) {
            const server = `https://${serverInfo?.data?.data?.data[0]?.ip}:${serverInfo?.data?.data?.data[0]?.port}`;
            const iframe = document.createElement('iframe');
            iframe.src = `${process.env.REACT_APP_RENDER_STREAMING_URL}/${stageMap[env]}?userId=${userId}&serverUrl=${server}`;
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.style.border = 'none';

            const container = document.getElementById('render-streaming-container');
            container.innerHTML = '';
            container.appendChild(iframe);

            setIframeLoaded(true); // Set flag to true to avoid future calls
        } 

        if (serverErr) {
            console.log('serverErr', serverErr);
        }

    }, [env, userId, roomName, token, serverInfo, iframeLoaded]);

    return <div id="render-streaming-container" style={{ height: '100vh', width: '100%' }} />;
};

export default RenderStreaming;
