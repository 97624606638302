import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { setColor } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../utils/generalUtils';
import { getAllForms } from './api/surveysController';
import { ReactComponent as ChevronIcon } from '../../../../assets/images/icons/Chevron.svg';
import Typography from '../../../../components/common/text/Typography';
import SurveysTable from './components/SurveysTable';
import { default as Button } from '../../../../components/common/button/Button';
import AddModal from './components/Add/AddModal';
import Modal from '../../components/Modal';
import { CACHE_QUERY_OPTIONS } from '../../../../constants';
import SearchBar from '../../../../components/common/searchBar/SearchBar';

const SurveysPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [updatedDateTime, setUpdatedDateTime] = useState('');
    const [createdDateTime, setCreatedDateTime] = useState('');
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [totalNumber, setTotalNumber] = useState(0);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const { data: surveysData, error, isLoading: formsLoading } = useQuery(
        ['forms'],
        () => getAllForms(),
        {
            ...CACHE_QUERY_OPTIONS(), 
        },
    );

    useEffect(() => {
        const formsCardsData = [];
        if (surveysData && surveysData.length > 0) {
            for (let i = 0; i < surveysData.length; i += 1) {
                const createdDateTime = formatDateTimeWithTimeZone(surveysData[i].createdAt);
                surveysData[i].createdAt = createdDateTime;
                formsCardsData.push({
                    ...surveysData[i],
                });
            }
        }
        formsCardsData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdDateTime));
        if(searchInput.length === 0) {   
            setFilteredTableData(formsCardsData); 
            setTotalNumber(formsCardsData.length);
        } else {
            const formsCardsSearchData = surveysData.filter(survey => {
                const text = searchInput.toLowerCase();
                const id = survey.id ? survey.id.toLowerCase() : '';
                const title = survey.title ? survey.title.toLowerCase() : '';
                const description = survey.description ? survey.description.toLowerCase() : '';
                return (
                    id.includes(text) ||
                    title.includes(text) ||
                    description.includes(text)
                );
            }).map(survey => {
                return {
                    ...survey,
                    createdDateTime: formatDateTimeWithTimeZone(survey.createdAt)
                };
            });
            setTotalNumber(formsCardsSearchData.length);
            setFilteredTableData(formsCardsSearchData || []);
        }
    }, [surveysData, searchInput]);

    const handleAddClick = () => {
        setIsModalAddOpen(true);
    }

    const handleSearchChange = (value) => {
        setSearchInput(value);  // Update search text state
    };

    return (
        <>
            <CardTable>
                <TableRow>
                        <Title
                            title={'Surveys'}
                            description={
                                'Manage all surveys/forms in Intraverse.'
                            }
                        />
                    <TableColumn>
                        <Button
                            variant={'OutlinedButton'}
                            // disabled={successMessageBanner !== ''}
                            label={'Add Survey'}
                            margin={'2rem 0 0 0'}
                            onClick={() => handleAddClick()}
                        />  
                    </TableColumn>
                </TableRow>
            </CardTable>

            <Body isLoading={formsLoading}>
                <>
                    <FilterContainer>
                        <SearchBar 
                            width="400px" 
                            placeholder="Search forms by ID, title, description and creation date..."
                            onChange={handleSearchChange} 
                        />
                    </FilterContainer>
                    <FilterContainer>
                        {searchInput.length !== 0 && filteredTableData.length === 0 && (
                            <MessageCenter signal>
                                <Typography variant="ConsoleDescription">Search Results:</Typography>
                                No forms found
                            </MessageCenter>
                        )} 
                        {searchInput.length !== 0 && filteredTableData.length !== 0 && (
                            <MessageCenter >
                                <Typography variant="ConsoleDescription">Search Results:</Typography>
                                {filteredTableData.length === 1 ? ` ${filteredTableData.length} form found` : `${filteredTableData.length} forms found`}
                            </MessageCenter>
                        )} 
                    </FilterContainer>

                    {isModalAddOpen && (
                        <Modal
                            isOpen={isModalAddOpen}
                            setIsOpen={setIsModalAddOpen}
                            children={
                                <AddModal
                                    surveyId={null}
                                    questionId={null}
                                    requestType="survey"
                                    closeAddModal={() => setIsModalAddOpen(false)}
                                    name={''}
                                />
                            }
                        />
                    )}
                    {surveysData && (
                        <>
                            <SurveysTable surveysData={filteredTableData} 
                            searchInput={searchInput} 
                             totalNumber={totalNumber} />
                        </>
                    )} 
                 </> 
            </Body>
            
        </>
    );
};

export default SurveysPage;

const CardTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const MessageCenter = styled.div`
    color: ${(props) => (props.signal ? setColor.errorColor : setColor.successColor)};
    margin: 1rem 0rem;
    display: flex;
    justify-content: flex-end;
`;