import { DeleteUser } from '../redux/actions/UserActions';
import { DeleteMerchant } from '../redux/actions/MerchantActions';
import {
    COREAPI_WEB_USERS_URL,
    COREAPI_WEB_EVENTS_URL,
    COREAPI_WEB_VANITY_URL,
    COREAPI_WEB_ROOM_URL,
    LOG_EVENTS_DEVICE_DATA,
    LOG_EVENTS_USER_LOGIN_DATA,
    LOG_EVENTS_AVATAR_SELECTION,
} from '../constants';
import axiosInstance, { API_POST, API_GET, API_PUT } from '../config/axiosConfig';
import { getFromSessionStorage } from '../hooks/useSessionStorage';
import { getDeviceInfo } from '../utils/generalUtils';

const COREAPIURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_USERS_URL}`;
const COREAPIEVENTURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_EVENTS_URL}`;
const COREAPIWEBVANITYURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_VANITY_URL}`;
const COREAPIWEBROOMURL = `${process.env.REACT_APP_CORE_API_URI}${COREAPI_WEB_ROOM_URL}`;

export async function LogInWithEmail(email, password) {
    try {
        const data = {
            email,
            password,
        };

        const respAxios = await API_POST(`${COREAPIURL}/login`, data);
        respAxios.data.status = respAxios.status;

        return respAxios.data;
    } catch (err) {
        return err;
    }
}

export async function RegisterGuestUser({
    email,
    displayName,
    vanityLinkId,
    optInMarketing,
    clientName,
    guestOnlyMode,
}) {
    try {
        const data = {
            email,
            displayName,
            vanityLinkId,
            optInMarketing: optInMarketing || false,
            clientName,
            isEmailVerified: false,
        };

        const respAxios = await API_POST(`${COREAPIURL}/login/vanity-link`, data);
        respAxios.data.status = respAxios.status;
        if (respAxios.data.status === 200) {
            const data = await respAxios.data;
            const deviceInfo = await getDeviceInfo();

            const eventJson = {
                guestLogin: guestOnlyMode,
                user: data.user,
                clientName,
            };

            await sendEventsLog(
                data.token,
                clientName,
                data.userId,
                LOG_EVENTS_USER_LOGIN_DATA,
                'login',
                eventJson,
                '',
                ''
            );

            await sendEventsLog(
                data.token,
                clientName,
                data.userId,
                LOG_EVENTS_DEVICE_DATA,
                'info',
                deviceInfo,
                '',
                ''
            );

            return data;
        }
        return null;
    } catch (err) {
        console.error(err);
        return err;
    }
}

export async function RegisterUser(
    firstName,
    lastName,
    email,
    password,
    username,
    optInMarketing,
    country
    // confirmPassword
) {
    try {
        const url = `${COREAPIURL}`;
        const data = {
            email,
            password,
            firstName: firstName || 'guest',
            lastName: lastName || 'user',
            displayName: username,
            // confirmPassword: password,
            createDefaultRoom: true,
            autoLogin: true,
            optInMarketing: optInMarketing || false,
            isEmailVerified: true,
            userCountry: country,
            userBirthday: null,
        };

        const response = await API_POST(url, data);

        if (response.status === 201) {
            const data = await response.data;
            const deviceInfo = await getDeviceInfo();
            await sendEventsLog(data.token, username, data.userId, LOG_EVENTS_DEVICE_DATA, 'info', deviceInfo, '', '');
            const selectedAvatarId = getFromSessionStorage('selectedAvatar');
            if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
                const avatarInfo = {
                    avatarpresetid: selectedAvatarId,
                };
                sendEventsLog(
                    data.token,
                    username,
                    data.userId || '',
                    LOG_EVENTS_AVATAR_SELECTION,
                    'info',
                    avatarInfo,
                    '',
                    ''
                );
            }
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during sign up:', error);
        return null;
    }
}

export async function UpdateUserProfileData(token, updatedFields) {
    try {
        const data = {
            ...updatedFields,
        };

        return await API_PUT(COREAPIURL, data);
    } catch (error) {
        console.error('Error during user data update:', error);
        return false;
    }
}

export async function UpdateUserBirthday(token, birthday, userId) {
    try {
        const userData = await getUserProfileData(token, userId);
        if (userData.data.birthdayUpdateCount >= 3) {
            throw new Error('Exceeded the allowed number of updates for birthday');
        }
        const birthdayClean = new Date(birthday);
        const newBirthdayCount = parseInt(userData.data.birthdayUpdateCount, 10) + 1;
        const data = {
            userBirthday: birthdayClean,
            birthdayUpdateCount: newBirthdayCount,
        };
        return await API_PUT(COREAPIURL, data);
    } catch (error) {
        console.error('Error during user birthday update:', error);
        return false;
    }
}

// eslint-disable-next-line consistent-return
export async function getUserProfileData(token, userId) {
    try {
        const response = await API_GET(`${COREAPIURL}/${userId}`);

        if (response.status === 200) {
            const userData = await response;
            return userData.data;
        }
    } catch (error) {
        console.error('Error during user profile data fetch:', error);
        return false;
    }
}

export async function UpdateUserEmail(token, newEmail) {
    try {
        const data = { email: newEmail };
        const response = await API_PUT(`${COREAPIURL}`, data);

        if (response.status === 200) {
            const responseData = response.data;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response.data;
        return {
            success: false,
            message: errorData,
        };
    } catch (error) {
        console.error('Error during user data update:', error);
        return false;
    }
}

export async function UpdateUserDisplayName(token, displayName) {
    try {
        const response = await API_PUT(`${COREAPIURL}`, {
            displayName,
        });

        if (response.status === 200) {
            const responseData = response.data;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response.data;
        return {
            success: false,
            message: errorData,
        };
    } catch (error) {
        console.error('Error during user data update:', error);
        return false;
    }
}

export async function signUpUser(displayName, email) {
    try {
        const response = await API_POST(`${COREAPIURL}/login`, { displayName, email });

        if (response.status === 200) {
            const data = await response.data;
            // send deviceInfo to event log
            const deviceInfo = await getDeviceInfo();
            await sendEventsLog(data.token, displayName, '', LOG_EVENTS_DEVICE_DATA, 'info', deviceInfo, '', '');
            // send avatar selection to action log
            const selectedAvatarId = getFromSessionStorage('selectedAvatar');
            if (selectedAvatarId !== '' || selectedAvatarId !== undefined || selectedAvatarId !== null) {
                const avatarInfo = {
                    avatarpresetid: selectedAvatarId,
                };
                sendEventsLog(data.token, displayName, '', LOG_EVENTS_AVATAR_SELECTION, 'info', avatarInfo, '', '');
            }
            return data;
        }

        return null;
    } catch (error) {
        console.error('Error during singn up:', error);
        return null;
    }
}

export async function getOwnerId(clientName) {
    try {
        const response = await API_GET(`${COREAPIWEBVANITYURL}/${clientName}`);
        if (response.status === 200) {
            const roomId = response?.data?.roomId;
            const ownerResp = await API_GET(`${COREAPIWEBROOMURL}/${roomId}`);
            if (ownerResp.status === 200) {
                const returnResp = {
                    ownerId: ownerResp.data.room.ownerId,
                    roomId,
                };
                return returnResp;
            }
        }
    } catch (error) {
        console.error('Error during user profile data fetch:', error);
        return false;
    }
}

export async function sendEventsLog(
    userToken,
    clientName,
    userId,
    eventName,
    eventType,
    eventJson,
    clientSessionId,
    envUnity
) {
    try {
        const respOwnerData = await getOwnerId(clientName);

        if (respOwnerData.ownerId) {
            const data = {
                source: 'web',
                buildId: '1',
                serverId: '1',
                roomType: clientName,
                eventTs: new Date().toISOString(),
                roomId: respOwnerData.roomId,
                ownerId: respOwnerData.ownerId,
                eventName,
                eventType,
                eventJson: JSON.stringify(eventJson),
                url: window.location.href,
                userId,
                clientSessionId,
                envUnity,
                envApi: process.env.REACT_APP_CORE_API_URI,
            };

            const response = await API_POST(`${COREAPIEVENTURL}/log`, data);
            if (response.status === 200) {
                return true;
            }

            return false;
        }
    } catch (error) {
        console.error('Error sending message:', error);
        return false;
    }
}

export async function LogInWithForgerock(code) {
    try {
        const response = await API_GET(`${COREAPIURL}/get-token/forgerock/${code}`);

        if (response.status === 400 || response.status === 500 || response.status === 401) {
            return response;
        }
        if (response.status === 200) {
            const accessToken = response.data.access_token;

            const userInfoResponse = await API_GET(`${COREAPIURL}/get-user/info/${accessToken}`);

            if (userInfoResponse.status === 400 || userInfoResponse.status === 500 || userInfoResponse.status === 401) {
                return userInfoResponse;
            }
            if (userInfoResponse.status === 200) {
                return userInfoResponse;
            }

            // const respData = {
            //       "data": {
            //         "sub": "cachu",
            //         "email": "cachu@hk.estee.com",
            //         "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0ZjU0NGNlYS1mMjMwLTQxMjUtYjgzOC1lY2M1ODFlYWEwYzMiLCJpYXQiOjE2OTY1NDUyNTgsImV4cCI6MTY5NjU0ODg1OCwicm9sZXMiOltdLCJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjUwMDEifQ.bgl2FOhwA1JWVI9WLGrw43NROP7rIjmdgAFHISTO3zk",
            //         "userId": "4f544cea-f230-4125-b838-ecc581eaa0c3"
            //     }
            //   };
        }
    } catch (err) {
        console.error('LogInWithForgerock Error::: ', err);
        return err;
    }
}

export async function Logout() {
    try {
        await API_POST(`${COREAPIURL}/logout`);
        DeleteUser();
        DeleteMerchant();
    } catch (err) {
        return 500;
    }
}

export async function forgotPasswordEmail(passwordResetURL, email) {
    try {
        const data = {
            email,
            passwordResetURL,
        };
        const response = await API_POST(`${COREAPIURL}/password`, data);

        if (response.status === 200) {
            const responseData = response;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response;
        return {
            success: false,
            message: errorData,
        };
    } catch (err) {
        console.error(err);
        return {
            success: false,
            error: err,
        };
    }
}

export async function ConfirmPasswordReset(token, newPassword) {
    try {
        const url = `${COREAPIURL}`;
        const data = {
            password: newPassword,
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await API_PUT(url, data, config);
        if (response.status === 200) {
            const responseData = response;
            return {
                success: true,
                message: responseData,
            };
        }
        const errorData = await response;

        return {
            status: response?.status,
            success: false,
            message: errorData,
        };
    } catch (err) {
        console.error(err);
        return {
            status: err,
            success: false,
        };
    }
}

export const currentPasswordChecker = async (currentPassword, token, userId) => {
    try {
        return await API_POST(`${COREAPIURL}/check/current-password/`, { password: currentPassword, userId });
    } catch (err) {
        return false;
    }
};

export const emailExistsChecker = async (email) => {
    try {
        // email is passed as part of the URL path, not in the body.
        const response = await API_GET(`${COREAPIURL}/check-email/${email}`);
        if (response && response.data.exists) {
            return true;
        }
        return false; // Email does not exist
    } catch (err) {
        console.error('Error checking email existence:', err);
        return false; // Assume false if there's an error during the check
    }
};

export const usernameExistsChecker = async (username) => {
    try {
        const response = await API_GET(`${COREAPIURL}/check-username/${username}`);
        if (response?.status === 200) {
            return response?.data?.exists;
        }
    } catch (err) {
        console.error('Error checking username existence:', err);
        return false;
    }
};

export const createAvatar = async (userId, avatarJsonData, token) => {
    try {
        const parsedData = JSON.stringify(avatarJsonData);
        return await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/avatar`, { userId, avatarJson: parsedData });
    } catch (error) {
        console.error('Error creating avatar:', error);
        throw error;
    }
};

export const checkAvatarExistsByUserId = async (userId) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/avatar/user/${userId}`);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error('Error checking avatar existence:', error);
        throw error;
    }
};

export const verifyToken = async (token) => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_CORE_API_URI}/web/users/verify_token`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            return true;
        }

        return false;
    } catch (error) {
        console.error('Error verifying token:', error);
        return false;
    }
};
