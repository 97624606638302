import { API_GET, API_POST } from '../config/axiosConfig';

export async function getFileUrl(fileId, token) {
    try {
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token !== '') {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/files/${fileId}`;
        const config = {
            method: 'GET',
            url,
            headers,
        };

        const response = await API_GET(url, config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function uploadFile(data, token) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('usageType', data.usageType);
    formData.append('targetUserId', data.targetUserId);
    formData.append('roomId', data.roomId);
    formData.append("file['originalname']", data.file.name);

    const url = `${process.env.REACT_APP_CORE_API_URI}/web/files`;

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };

    try {
        const response = await API_POST(url, formData, config);
        return response;
    } catch (error) {
        console.error('error:', error);
        return error;
    }
}

async function fetchImageBlob(imageUrl) {
    try {
        const response = await fetch(imageUrl);

        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
        }

        const contentType = response.headers.get('Content-Type') || 'application/octet-stream';
        const blob = await response.blob();

        return { blob, contentType };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function uploadFileFromUrl(data, token) {
    try {
        const imageUrl = data.imageUrl;
        const { blob, contentType } = await fetchImageBlob(imageUrl);

        const formData = new FormData();
        formData.append('file', new File([blob], data.fileName || 'image.jpg', { type: contentType }));
        formData.append('usageType', data.usageType);
        formData.append('targetUserId', data.targetUserId);
        formData.append('roomId', data.roomId);
        formData.append("file['originalname']", data.fileName || 'image.jpg');

        const url = `${process.env.REACT_APP_CORE_API_URI}/web/files`;
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        };

        const response = await API_POST(url, formData, config);
        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        return error;
    }
}
