import React from 'react';
import styled from 'styled-components';
import TopStars from './images/TopStars.png';
import ModalCloseIcon from './images/ModalCloseIcon.png';
import Button from '../../components/common/button/Button';
import Typography from '../../components/common/text/Typography';
import Crown from './images/Crown.png';

const NoteSubmitSuccessModal = ({ onClose, useEmailCreator, roomAdminName }) => (
    <>
        <Overlay onClick={onClose} />
        <ModalContainer>
            <ModalCloseIconBox onClick={onClose} src={ModalCloseIcon} alt="ModalCloseIcon" />
            <ModalContent>
                <Typography variant="guestbookP" align="center" FontWeight="700" fontSize={'38px'} Color="#E4DEFF">
                    {useEmailCreator ? 'Email sent!' : 'Note Submitted!'}
                </Typography>
                {useEmailCreator && (
                    <p style={{ fontSize: '22px', color: '#CED1D9' }}>
                        Your note has been emailed to
                        <span style={{ color: '#58F5FF', fontWeight: '700' }}>
                            <img
                                src={Crown}
                                alt="crown"
                                style={{ margin: '-2px 5px', display: 'inline', verticalAlign: 'bottom' }}
                            />
                            {roomAdminName !== '' ? roomAdminName : 'Owner'}
                        </span>
                    </p>
                )}
                <p style={{ fontSize: '22px', color: '#CED1D9' }}>
                    {!useEmailCreator && `Your note is being reviewed. Once approved, it will appear in the guestbook.`}
                </p>
                <Button
                    label="Sounds good"
                    borderRadius="0px"
                    backgroundColor="rgba(37, 24, 89, 0.50)"
                    variant="OutlinedButton"
                    onClick={onClose}
                    margin="2rem 0"
                    buttonWidth="100%"
                />
            </ModalContent>
        </ModalContainer>
    </>
);

export default NoteSubmitSuccessModal;

const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-start;
    width: 700px;
    height: fit-content;
    background-color: #0e0517;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${TopStars});
    background-size: 100% 50%;
    background-position: top center;
    background-repeat: no-repeat;
    border: 4px solid #3d364f;
    position: fixed;
    top: 25%;
    padding: 0 2rem;
    z-index: 10;

    @media (max-width: 768px) {
        width: 80%;
        padding: 0rem;
    }
`;

const ModalContent = styled.div`
    color: white;
    border-radius: 10px;
    text-align: center;
    padding: 2rem;

    @media (max-width: 700px) {
        width: auto;
    }
`;
const ModalCloseIconBox = styled.img`
    position: absolute;
    top: -30px;
    right: -45px;
    height: 100px;
    width: 90px;
    cursor: pointer;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;
