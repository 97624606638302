/* eslint-disable */
import { grey } from '@mui/material/colors';
import { css, keyframes } from 'styled-components';

// const highContrast = localStorage.getItem('high_contrast_theme') === 'true';
// const dark = highContrast
//     ? true
//     : localStorage.getItem('dark_theme') === 'true';

export const setColor = {
    primary: '#060b26',
    secondary: '#1a1f39',
    accent1: '#0084f4',
    accent2: '#005ead',
    accent3: '#004177',
    accent4: '#beaeff',
    hightlight: '#ffffff',
    backgroundLinear1: '#003663',
    backgroundLinear2: '#090e38',
    textPrimary: '#ffffff',
    textSecondary: 'blue',
    textGray: '#404040',
    buttonLight: '#F3F6F8',
    increase: '#2ac670',
    textLightGray: 'grey',
    textLighterGray: 'DADADE',
    accentPurple: '#BA21F9',
    lightAccentPurple: '#faedff',
    inputGreyColor: '#ffffff19',
    inputGreyColorTwo: '#ffffff33',
    inputSectionColor: '#1b1b23e5',
    discoverseInput: '#303a60cc',
    backgroundAvatar: '#0b0b2cbf',
    decrease: '#ff6252',
    success: '#2ac670',
    warning: '#ff6252',
    buttonTextBlack: '#000',
    greydisabled: '#4f4c5c',
    inputGreen: '#92ECC7',
    greyBackground: '#2B2A38',
    startedContainer: '#1b1b234c',
    // new Colors
    purpleDark: '#beaeff19',
    black: '#000000',
    greyLine: '#ffffff4c',
    boxShadowContainer: '#0000004c',
    lightPurpleCheckbox: '#c0b3f6',
    grey1: 'rgb(80, 76, 92)',
    iconColor: '#9DA0A7',
    iconColor2: '#7A7784',
    iconColor3: '#beaeff',
    textColor: '#CACACA',
    spanTextColor: '#FFE664',
    darkPurple: '#beaeff7f',
    spanTextColorThree: '#ffe663',
    inputText: '#FBFCFF',
    inputBgColor: '#1B1B23',
    darkPurpleBackground: '#1B1B23',
    solidDarkPurpleBackground: '#2B2A39',
    loadingButtonBg: '#504C5C',
    spotifyText: '#1ED760',
    greenSuccess: '#1563437f',
    redColor: '#FF3D57',
    darkGreen: '#105136E5',
    greyIcon: '#B0B0B8',
    grey: grey,
    greyBox: '#504d59',
    blueBright: '#2259ff',
    borderColor: '#dcdce0',
    sliderContainerBg: '#404040',
    boxContainerBg: '#968DFD',
    confirmMessage: '#DD42CE',
    gradient: '#e82471',
    errorBox: '#661010',
    successColor: '#93ebc7',
    successBox: '#156343',
    errorColor: '#ff4842', // previously #FF8484
    lightPink: '#ff8484', // previously #FF8484
    circularProgress: '#ced1d9',
    nextStepText: '#5c567b',
    passwordValidBox: '#2b2a38',
    innerProfileContainer: '#121218',
    purpleDarkTwo: '#232230',
    modalOutline: '#24242f',
    spanTextcolor2: '#ffe664',
    onHoverTextcolor2: '#252333',
    purpleAccentBackground: '#252333',
    darkPurpleOverlay: '#130C2DB2',
    tickerBorder: '#3c384e',
    tablePurple: '#3c384f',
    landingBackground: '#15151b',
    heroBackground: '#482b9b',
    mobileFooterBackground: '#38344A',
    yellowText: '#ffe664',
    purpleNavigation: '#beaeff33',
    redBackground: '#d9d9d933',
    purpleBackground: '#2518597f',
    greyBoxPurple: '#454250',
    lightPurple: '#e4deff',
    boxShadowColor: '#00000013',
    blackColor: '#000000',
    greyColorBackground: '#7a7878',
    greyLight: '#ffffff33',
    lightGrey: '#808080',
    blackDisbaled: '#0000007',
    lightRed: '#f4c0c5',
    brightPurple: '#600c8e',
    lightBrightPurple: '#a480b0',
    whiteLight: '#c4c4c4',
    lightRedTwo: '#ffe0e3',
    green: '008000',
    boxshadowOne: '#00000066',
    backgroundGrey: '#807e7e33',
    lightGreyLabel: '#a0aec0',
    redLightWarning: '#cd716a',
    aquaGreen: '#abdecb',
    white: '#ffffff',
    failedRed: '#ff8383',
    searchbarWhite: '#f0f8ff',
    fileuploadGrey: '#a9a9a9',
    errorMessageOne: '#6610107f',
    greyTextSegment: '#fbfbfbcc',
    errorMessageTwo: '#0d4d767f',
    lightBlue: '#83CEFF',
    darkBlue: '#1b1b23bf',
    greenLight: '#93ecc7',
    lightPurpleInput: '#22A39',
    darkBlueContest: '#1b1b23a5',
    contestHeaderBg: '#313139',
    sendMessage: '#1b1b237f',
    blackOutline: '#00000033',
    pinkButtonBgGradient: 'linear-gradient(45deg, #F0153F 5.13%, #DD42CE 100.27%)',
    linearGradientScrollbar: 'linear-gradient(180deg, rgba(27, 27, 35, 1) 0%, rgba(27, 27, 35, 1) 100%)',
    containerGradient: 'linear-gradient(73deg, rgba(11, 11, 44, 0.75) 0%, rgba(33, 13, 53, 0.75) 98.54%)',
};

// prettier-ignore
export const setInputColors = {
};

//removed setButton and replaced with as setAlert in code
export const setAlert = {
    danger: '#f44336',
    success: '#4CAF50',
    info: '#2196F3',
    warning: '#ff9800',
};

export const setFont = {
    //main:
    // serif: "font-family:
    // main: "font-family:,
    sansPro: "'Source Sans Pro', sans-serif",
    barlow: "'Barlow', sans-serif",
    barlowBold: 'Barlow-Bold',
    barlowCondensed: "'Barlow Condensed', sans-serif",
    signikaNegative: "'Signika Negative', sans-serif",
};

export const setBoxShadow = {
    default: '0px 5px 8px rgba(0, 0, 0, 0.4)',
    light: '0 0 15px -6px rgba(0,0,0,0.25);',
    dark: '0 0 15px -6px rgba(0,0,0,0.55);',
    darkest: '0 0 15px -6px rgba(0,0,0,0.75);',
    lightPressed: '0 0 25px -6px rgba(0,0,0,0.25);',
    darkPressed: '0 0 25px -6px rgba(0,0,0,0.55);',
    darkestPressed: '0 0 25px -6px rgba(0,0,0,0.75);',
};

//setting defaults
export const setFlex = ({ x = 'center', y = 'center' } = {}) => `
display: flex;
align-items: ${y};
justify-content: ${x};
`;
export const setBackground = () => `
`;

export const setLetterSpacing = (spacing = 2) => `
	letter-spacing: ${spacing}px;
`;

export const setBorder = ({ width = '2px', style = 'solid', color = setColor.mainWhite } = {}) => `
	border: ${width} ${style} ${color};
`;

//code for the object and fn are from styled components docs on how to create helper function for media queries
//this code has been removed from the official docs but it's still good to have

//object map of breakpoints for easy customization
const sizes = {
    large: 1200,
    desktop: 992,
    tablet: 768,
    phone: 576,
};

//iterate through the sizes and create a media template
//you can change desktop-centric or mobile-centric design by changing max-width/min-width property inside
export const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${sizes[label] / 16}em) {
            ${css(...args)}
        }
    `;

    return acc;
}, {});

//animation helper fn example - in this case the fn arguments are only the translateY value, but this is the gist of it

export const fadeIn = (start, mid, end) => {
    const animation = keyframes`
        0%{
            opacity: 0;
            transform: translateY(${start});
        }
        50%{
            transform: translateY(${mid});
        }
        100%{
            opacity: 1;
            transform: translateY(${end});
        }
    `;
    return css`
        animation: ${animation} 1s ease-in-out;
    `;
};

//animation transition helper function
export const setTransition = ({ property = 'all', time = '0.3s', timing = 'ease-in-out' } = {}) => `
	transition: ${property} ${time} ${timing};
`;
