import axiosInstance, { API_GET, API_PUT } from '../config/axiosConfig';

export async function putBannerImage(roomId, data, token) {
    try {
        const response = await API_PUT(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/banner_image/${roomId}`,
            data
        );
        return response;
    } catch (error) {
        return error;
    }
}
export async function getPersonalizationItems(roomId, token) {
    try {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/${roomId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return response;
    } catch (error) {
        return error;
    }
}
export async function putPersonalizationItem(roomPersonalizationItemId, data, token) {
    try {
        const response = await API_PUT(
            `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms/room_personalization_items/${roomPersonalizationItemId}`,
            data
        );
        return response;
    } catch (error) {
        return error;
    }
}

export const getUserRoomPermission = async (roomId, token) => {
    try {
        const response = await axiosInstance.get(
            `${process.env.REACT_APP_CORE_API_URI}/web/rooms/permission/${roomId}`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status === 200) {
            return true;
        }

        return false;
    } catch (error) {
        console.error('Error verifying token:', error);
        return false;
    }
};

export const getRoomSettingsById = async (id) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/${id}/settings`);

        if (response) {
            return response;
        }
    } catch (error) {
        console.error('Error grabbing room settings:', error);
        return null;
    }
};
