import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import NavigationMenu from './components/NavigationMenu';
import MainContainer from './layout/MainContainer';
import { isMobileDevice } from '../../utils/generalUtils';
import Banner from '../../components/layout/ui/Banner';

const routesWithSpecialWidth = ['room_personalization'];

const Console = () => {
    const isMobile = isMobileDevice();
    const [modifyWidth, setModifyWidth] = useState(false);

    const [openBanner, setOpenBanner] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerType, setBannerType] = useState('ERROR');

    // TO DO reducer
    // const runBanner = () => {
    //     setBannerMessage('Error updating record');
    //     setBannerType('ERROR');
    //     setOpenBanner(true);

    //     // setBannerMessage('Successfully updating record');
    //     // setBannerType('SUCCESS');
    //     // setOpenBanner(true);
    // };

    // useEffect(() => {
    //     // runBanner();
    // }, []);

    useEffect(() => {
        const currentPath = window.location.pathname;
        const shouldModifyWidth = routesWithSpecialWidth.some((route) => currentPath.includes(route));
        setModifyWidth(shouldModifyWidth);
    }, [window.location.pathname]);

    return (
        <>
            <Banner
                message={bannerMessage}
                type={bannerType}
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                topDisplay
                showCloseButton={false}
                timer={5000}
                top={100}
                margin={'80px'}
            />
            <ConsoleContainer>
                <NavigationMenu />
                <MainContainer modifyWidth={modifyWidth}>
                    <Outlet />
                </MainContainer>
            </ConsoleContainer>
        </>
    );
};

export default Console;

const ConsoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #1b1b23;
`;
