import { Navigate, useRoutes, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RegisterUserPage from './pages/login/RegisterUserPage';
import VanityLogin from './pages/vanityLogin/VanityLogin';
import StartingPage from './pages/startingPage/StartingPage';
import VanityStartingPage from './pages/vanityLogin/VanityStartingPage';
import Login from './pages/login/Login';
import Console from './pages/console/Console';
import { Logout } from './api/UserController';
import Profile from './pages/console/user/profile/Profile';
import Wallet from './pages/console/user/wallet/Wallet';
import Orders from './pages/console/user/orders/Orders';
import Rooms from './pages/console/admin/rooms/Rooms';
import RoomsDetails from './pages/console/admin/rooms/components/RoomsDetails';
import RoomSettings from './pages/console/admin/rooms/components/RoomSettings';
import RoomRelations from './pages/console/admin/rooms/components/RoomRelations';
import RoomUsers from './pages/console/admin/rooms/components/RoomUsers';
import RoomGames from './pages/console/admin/rooms/components/RoomGames';
import { fetchUserId, fetchUserName, fetchUserRole, fetchUserToken } from './redux/slices/users';
import { SUPERADMIN_ROLE, ADMIN_ROLE } from './constants';
import NotFound from './pages/Page404';
import Integrations from './pages/console/user/integrations/Integrations';
import Users from './pages/console/admin/users/Users';
import VanityLinks from './pages/console/admin/vanityLinks/VanityLinks';
import GuestCheckout from './pages/guestCheckout/GuestCheckout';
import Checkout from './pages/guestCheckout/components/checkout';
import CheckoutSuccess from './pages/guestCheckout/components/success';
import CheckoutCancel from './pages/guestCheckout/components/cancel';
import Tables from './pages/console/admin/tables/Tables';
import DynamicTable from './pages/console/admin/tables/DynamicTable';
import RecordDetails from './pages/console/admin/record/RecordDetails';
import ForgotPassword from './pages/login/ForgotPassword';
import UpdatePassword from './pages/login/UpdatePassword';
import Merchants from './pages/console/admin/merchant/Merchants';
import MerchantsDetails from './pages/console/admin/merchant/components/MerchantsDetails';
import DynamicTableFiles from './pages/console/admin/files/DynamicTableFiles';
import Surveys from './pages/console/admin/surveys/Surveys';
import SurveysQuestions from './pages/console/admin/surveys/components/SurveysQuestionsTable';
import QuestionOptions from './pages/console/admin/surveys/components/QuestionOptionsTable';
import LionGamingCasino from './features/lionGaming/LionGamingCasino';
import NPCDialogues from './pages/console/admin/npc_dialogues/NPCDialogues';
import ImageEditor from './pages/console/admin/image_editor/ImageEditor';
import CustomizationPage from './pages/console/user/customization/CustomizationPage';
import Roles from './pages/console/admin/rolesPermissions/Roles';
import Permissions from './pages/console/admin/rolesPermissions/Permissions';
import MyRooms from './pages/console/user/myRooms/MyRooms';
import WrapperWithCookieAuth from './hooks/wrappers/WrapperWithCookieAuth';
import WrapperCheckUserRoomPermission from './hooks/wrappers/WrapperCheckUserRoomPermission';
import Live from './pages/landingPages/live/Live';
import RenderStreaming from './pages/render-stream/RenderStreaming';
import Pro from './pages/landingPages/pro/Pro';
import Guestbook from './features/guestbook/Guestbook';
import GuestBookPage from './pages/console/user/guestBook/GuestBook';

window.addEventListener('storage', (e) => {
    if (e.key === 'persist:user') {
        const json = JSON.parse(e.newValue).user;
        if (!JSON.parse(json).user) {
            Logout();
        }
    }
});

const DynamicLoginComponent = () => {
    const location = useLocation();
    const path = location.pathname;

    // Comment or remove below to change old vanity pages to new vanity flow
    if (path === '/teddyswims' || path === '/discoverse' || path.includes('/discoverse')) {
        return <VanityLogin />;
    }
    return <VanityStartingPage />;
};

export default function Router() {
    const user = useSelector(fetchUserName);
    const role = useSelector(fetchUserRole);
    const token = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);
    return useRoutes([
        {
            path: 'reset-password',
            element: (
                <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
                    {' '}
                    <ForgotPassword />
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: 'reset-password/:token',
            element: <UpdatePassword />,
        },
        {
            path: ':roomName/:productId/guest-checkout',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <GuestCheckout />
                </UserConsoleRoute>
            ),
            children: [
                {
                    path: '',
                    element: <Checkout />,
                },
                {
                    path: 'success',
                    element: <CheckoutSuccess />,
                },
                {
                    path: 'cancel',
                    element: <CheckoutCancel />,
                },
            ],
        },
        {
            path: 'console',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <Console />
                </UserConsoleRoute>
            ),
            children: [
                {
                    path: 'profile',
                    element: <Profile />,
                },
                {
                    path: 'orders',
                    element: <Orders />,
                },
                {
                    path: 'wallet',
                    element: <Wallet />,
                },
                {
                    path: 'integrations',
                    element: <Integrations />,
                },
                {
                    path: ':userId/my-rooms',
                    element: <MyRooms />,
                },
                {
                    path: 'users',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Users />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Rooms />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomsDetails />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id/settings',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomSettings />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id/guest-book',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <GuestBookPage />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id/casino-games',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomGames />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id/subrooms',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomRelations />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'rooms/:id/users',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RoomUsers />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'record_details',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <RecordDetails />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'vanityLinks',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <VanityLinks />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'tables',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Tables />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'files_dashboard',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <DynamicTableFiles />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'table/:tableName',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <DynamicTable />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'merchants',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Merchants />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'merchants/:id',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <MerchantsDetails />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'surveys',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Surveys />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'surveys/:surveyId/questions',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <SurveysQuestions />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'surveys/:surveyId/questions/:questionId/options',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <QuestionOptions />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'npc_dialogues/:roomId',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <NPCDialogues />
                        </RedirectIfNotAdmin>
                    ),
                },

                {
                    path: 'image_editor',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <ImageEditor />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: 'roles',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Roles />
                        </RedirectIfNotAdmin>
                    ),
                },
                {
                    path: ':roleId/permissions',
                    element: (
                        <RedirectIfNotAdmin role={role}>
                            <Permissions />
                        </RedirectIfNotAdmin>
                    ),
                },
            ],
        },
        {
            path: 'tz-render/:roomName',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <RenderStreaming />
                </UserConsoleRoute>
            ),
        },
        {
            path: '/room_personalization',
            element: (
                <WrapperWithCookieAuth>
                    <WrapperCheckUserRoomPermission>
                        {({ token }) => <CustomizationPage token={token} />}
                    </WrapperCheckUserRoomPermission>
                </WrapperWithCookieAuth>
            ),
        },
        {
            path: 'shell',
            element: <LionGamingCasino />,
        },
        {
            path: 'autologin',
            element: <VanityStartingPage autoLogin />,
        },
        {
            path: 'login',
            element: (
                <RedirectIfLoggedIn user={user} token={token} userId={userId} role={role}>
                    <Login style={{ Index: 1000 }} />
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: 'pro',
            element: <Pro />,
        },
        {
            path: 'live',
            element: <Live />,
        },
        {
            path: 'guestbook',
            element: (
                <UserConsoleRoute user={user} token={token} userId={userId} role={role}>
                    <Guestbook />
                </UserConsoleRoute>
            ),
        },
        {
            path: 'register',
            element: (
                <RedirectIfLoggedIn user={user}>
                    <RegisterUserPage />
                </RedirectIfLoggedIn>
            ),
        },
        {
            path: '404',
            element: <NotFound />,
        },
        {
            path: '*',
            element: <DynamicLoginComponent />,
        },
        {
            path: '/',
            element: !user ? <Live /> : <StartingPage />,
            children: [
                { path: '/', element: <Navigate to="/dashboard/app" /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: user ? <Navigate to="/404" /> : <Navigate to="/login" /> },
            ],
        },
    ]);
}

function ProtectedRoute({ children, user, token, userId, role }) {
    if (
        (user === '' && token === '' && userId === '' && role === '') ||
        role !== SUPERADMIN_ROLE ||
        role !== ADMIN_ROLE
    ) {
        return <Navigate to="/" replace />;
    }
    return children;
}
ProtectedRoute.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
    role: PropTypes.any,
};
function UserConsoleRoute({ children, user, token, userId, role }) {
    if (user === '' && token === '' && userId === '' && role === '') {
        return <Navigate to="/" replace />;
    }
    return children;
}
UserConsoleRoute.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
    role: PropTypes.any,
};

function RedirectIfLoggedIn({ children, user, token, userId }) {
    if (user !== '' && token !== '' && userId !== '') {
        // if (role === SUPERADMIN_ROLE) {
        //   return <Navigate to="/call-to-action" replace />;
        // }
        return <Navigate to="/" replace />;
    }

    return children;
}

RedirectIfLoggedIn.propTypes = {
    children: PropTypes.any,
    user: PropTypes.any,
    token: PropTypes.any,
    userId: PropTypes.any,
};

function RedirectIfNotAdmin({ role, children }) {
    if (role !== SUPERADMIN_ROLE && role !== ADMIN_ROLE) {
        return <Navigate to="/console/profile" replace />;
    }
    return children;
}

RedirectIfNotAdmin.propTypes = {
    role: PropTypes.any,
    children: PropTypes.any,
};
